import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button
} from "shards-react";
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";
import IdeaAttachmentModal from "./common/IdeaAttachmentModal";


const ImplementedSuccess = ({comments, file, currIdea, form, hideArrow = false, onBack}) => {
    const [modalVisible, setModalVisible] = useState(false)
    const { t } = useTranslation();
    return (
        <div>
            <div>
                {file && <IdeaAttachmentModal ideaItem={file} onClick={setModalVisible} isVisible={modalVisible} file={file} type="file" />}

                <h3 style={{ width: "100%", textAlign: "center", color: "#000", paddingTop: "50px", display: "inline-flex", justifyContent: "center" }}>Implementation Analysis</h3>

      
                <p style={{ color: "#000", textAlign: "center", width: "100%" }}>Outcome: {form.toLowerCase() === "duplicate" ? " Duplicated" : " Not Implemented"}</p>
                <div>
                    <p style={{ display: "flex", gap: "20px", alignItems: "center" }}><strong style={{ color: "#000", width: "76px" }}>Owner:</strong>{currIdea.implementationLeader || "N/A"}</p>
                    <p style={{ display: "flex", gap: "20px", alignItems: "center" }}><strong style={{ color: "#000", width: "76px" }}>Coach(es):</strong>{currIdea.coaches.map((coach) => coach) || "N/A"}</p>
                    <p style={{ display: "flex", gap: "20px", alignItems: "center" }}><strong style={{ color: "#000", width: "76px" }}>Comments:</strong>{comments || "N/A"}</p>
                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}><strong style={{ color: "#000", width: "76px" }}>Attachment:</strong>
                        {
                            file ?
                            <Button
                                    onClick={() => {
                                        setModalVisible(true)
                                    }}
                                    style={{ display: 'flex' }}
                                  >
                                    View File
                                  </Button>: <p style={{ marginBottom: "0" }}>No attachment</p>
                        }
                    </div>

                </div>
                <div>
                    <button style={{ border: "none", backgroundColor: "transparent" }} onClick={() => onBack()}>
                        {!hideArrow && <PreviousIcon
                            className="functionalButton mr-auto d-block"
                            style={{ minWidth: 140, maxWidth: 140, marginTop: "40px" }} /> }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ImplementedSuccess