import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Row } from "shards-react";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import colors from "../../utils/colors";
import QuestionForm from '../common/QuestionForm';


function QuestionItem({question, updateQuestions}) {
  const [ideaStage, setIdeaStage] = useState(1)
  const [canGoNext, setCanGoNext] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [questionIsOpen, setQuestionIsOpen] = useState(true)
  const [myQuestion, setMyQuestion] = useState(question)

  const setInitialTitle =  () => {
  }

  useEffect(() => {
    setInitialTitle()
  });

  const setFinished = () => {
    setIdeaStage(3)
  }

  const changeBtnStatus = (status) => {

   setCanGoNext(status)
  }

  const changeContinueBtnStatus = (status) => {

   setCanContinue(status)
  }

  const refresh = () => {
    updateQuestions()
  }
  const openQuestion = () => {
    setMyQuestion('')
    setQuestionIsOpen(true)
  }
  const categoryNameEnglish = myQuestion?"Edit Question":"New Question"


  return (
    <Row>
      {/* Latest Orders */}
      <Col lg="12" className="m-auto">
              <div style={{width: '100%', borderStyle: 'solid',borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 5, display: 'flex'}}>

                {/* Change Status Color of Category */}
                {/* {categoryStatus && <GreenIcon className="ml-4 my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></GreenIcon>}
                {!categoryStatus && <RedIcon className="ml-4 my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></RedIcon>} */}
                

                <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
                    <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>{categoryNameEnglish}</h6>
                </div>

                { questionIsOpen && <ArrowUpIcon onClick={() => setQuestionIsOpen(false)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowUpIcon> }
                { !questionIsOpen && <ArrowDownIcon onClick={() => openQuestion()} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowDownIcon>}
              
              </div>
              { questionIsOpen &&
              <div style={{backgroundColor: '#F6F6F6',}}>
               <QuestionForm refreshQuestions={refresh} question={myQuestion} canSubmit={canSubmit} setFinishedSaving={() => setFinished()} ideaStage={ideaStage} changeStatus={(status) => changeBtnStatus(status)} changeContinueStatus={(status) => changeContinueBtnStatus(status)} changeIdeaStage={() => this.changeIdeaStage()}/>
              </div>
              }
      </Col>
    </Row>
  )}

  QuestionItem.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

QuestionItem.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default QuestionItem;
