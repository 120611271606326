import Parse from 'parse';
import React from "react";
import { withTranslation } from "react-i18next";
import {
    Card,
    CardBody,
    Col, Container,
    Row
} from "shards-react";
import BarChart from "../components/charts/BarChart";
import PageTitle from "../components/common/PageTitle";
import IdeaFilterManagerLite from "../components/IdeaFilterManagerLite";
import ImplementDashboardTable from "../components/ImplementDashboardTable";
import ResponsiveTable from "../components/responsive-table/ResponsiveTable";
import { getImplementOpportunitiesWithFilters, getInbox, getOpportunityById } from "../services/implementService";
import reportsService from "../services/reportsService";
import { authUser } from '../services/userService';
import ImplementIdea from './ImplementIdea';

class ImplementDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ideas: [],
            progress: [],
            pageSize: 5,
            pageSizeOptions: [5, 10, 15, 20, 25, 30],
            search: '',
            resetFilters: false,
            categoryName: '',
            departmentName: '',
            evalComm: '',
            statusName: '',
            ideaType: '',
            ideaQuestion: '',
            filteredData: [],
            isLoading: false,
            viewAs: '',
            ownerData: [],
            coachesData: [],
            user: null,
            currIdea: null,
            openIdea: false
        };

    }

    componentWillMount() {
        this.fetchIdeas();
    }

    componentDidMount() {
        const currentUser = authUser();
        this.setState({
            user: currentUser
        })
    }

    componentDidUpdate(prevProps) {
        const { ideas } = this.state;

        if (
            prevProps.category !== this.props.category ||
            prevProps.status !== this.props.status ||
            prevProps.ideaType !== this.props.ideaType ||
            prevProps.department !== this.props.department ||
            prevProps.viewAs !== this.props.viewAs
        ) {

            this.filterIdeas(
                this.props.category,      
                ideas,                    
                this.props.department,    
                this.props.ideaType,      
                false,                    
                this.props.viewAs
            );

        }
    }

    async fetchIdeas() {
        const reportData = await reportsService.fetchReportsImplement();

        const ownerData = reportData.ownersWithMostOpportunities;
        const coachesData = reportData.coachesWithMostOpportunities;

        const inbox = await getInbox('dashboard');
        this.setState({ ideas: inbox, progress: reportData, ownerData, coachesData });
    }

    async handleViewClick(idea) {
        const opportunityId = idea.id
        const opportunity = await getOpportunityById(opportunityId)
        this.setState({
            currIdea: opportunity,
            openIdea: true
        })
    }


    onCategoryChange(categoryName) {
        var selectedCategoryName = categoryName
        if (categoryName == "All" || categoryName == "Todas") {
            selectedCategoryName = ""
        }

        this.setState({
            categoryName: selectedCategoryName,
            resetFilters: false
        })
    }

    onDepartmentChange(categoryName) {
        var selectedCategoryName = categoryName
        if (categoryName == "All" || categoryName == "Todas") {
            selectedCategoryName = ""
        }

        this.setState({
            departmentName: selectedCategoryName,
            resetFilters: false
        })
    }

    onEvalCommChange(evalComm) {
        this.setState({
            evalComm: evalComm,
            resetFilters: false
        })
    }

    onTransferCommitteeChange(status) {
        this.onTypeChange('')
        this.setState({
            departmentName: '',
            categoryName: '',
            statusName: status ? 'Transfer Committee' : '',
            resetFilters: false,
        })
    }

    onStatusChange(statusName) {
        var selectedStatusName = ""
        if (statusName == "Submitted" || statusName == 'Sometidas') {
            selectedStatusName = "SOMETIDA"
        } else if (statusName == "Done" || statusName == 'Completadas') {
            selectedStatusName = "problema"
        }
        this.setState({
            statusName: selectedStatusName
        })
    }

    onTypeChange(ideaType) {

        this.setState({
            ideaType: ideaType
        })
    }

    onViewAsChange(viewAs) {
        this.setState({
            viewAs: viewAs,
        })
    }

    onQuestionChange(question) {
        this.setState({
            ideaQuestion: question
        })
    }


    toggle(item) {

        this.setState({
            open: !this.state.open,
            ideaItem: item,
            viewIdea: false
        });
    }

    deleteIdea(item) {
        const YourClassName = Parse.Object.extend('Idea');
        const objectToDelete = new YourClassName();

        objectToDelete.id = item.id;

        objectToDelete.destroy().then(
            (response) => {
                console.log('Item deleted successfully', response);
            },
            (error) => {
                console.error('Error deleting item', error);
            }
        );
    }

    showIdea = async (item, isDuplicate) => {
        // Update the URL without reloading the page
        if (!isDuplicate) {
            if (!window.location.pathname.includes('/evaluate-')) {
                window.history.pushState({}, '', `/evaluate-${item.id}`);
            }
        }

        try {
            // Fetch the opportunity data by ID
            // const opportunityData = await fetchOpportunityById(item.id);

            // Set the fetched data into the state
            this.setState({
                open: true,
                ideaItem: item, // Set the fetched opportunity data
                viewIdea: true,
                isDuplicate,
                loadingIdea: false,
            });
        } catch (error) {
            console.error('Error fetching opportunity:', error);
            this.setState({ loadingIdea: false });
        }
    };

    async filterIdeas(categories, ideas, departments, ideaTypes, transferCommittee, viewAs) {
        const { evalComm } = this.props;
        const { isLoading } = this.state;
        if (isLoading) return

        const categoryIds = (categories || []).map(category => category.value);
        const ideaTypeIds = (ideaTypes || []).map(ideaType => ideaType.value);
        const viewAsIds = (viewAs || []).map(v => v.value);
        const departmentIds = (departments || []).map(department => department.value);


        const filters = {
            categoriesIds: categoryIds,
            typesIds: ideaTypeIds,
            departmentsIds: departmentIds,
            committeesIds: transferCommittee ? evalComm : undefined,
            isTransferred: transferCommittee,
            viewAs: viewAsIds
        };

        this.setState({ isLoading: true });

        try {
            const filteredData = await getImplementOpportunitiesWithFilters(filters);
            this.setState({ filteredData, ideas: filteredData });
        } catch (error) {
            this.setState({ isLoading: false });
            console.error('Error filtering ideas:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }


    render() {
        const { progress } = this.state;
        const { t } = this.props;

        const chartData = {
            "series":
                [
                    {
                        "name": "Ideas by Status", "data":
                            [
                                { "x": "25%", "y": progress.opportunities ? progress.opportunities.progress['25'] : 0 },
                                { "x": "50%", "y": progress.opportunities ? progress.opportunities.progress['50'] : 0 },
                                { "x": "75%", "y": progress.opportunities ? progress.opportunities.progress['75'] : 0 },
                                { "x": "100%", "y": progress.opportunities ? progress.opportunities.progress['100'] : 0 }
                            ]
                    }],
            "options":
            {
                "chart": { "type": "bar", "height": 350 },
                "plotOptions": { "bar": { "horizontal": false, "columnWidth": "55%", "endingShape": "rounded" } },
                "dataLabels": { "enabled": false }, "xaxis": { "categories": ["25%", "50%", "75%", "100%"] },
                "yaxis": { "title": { "text": "Quantity of Opportunties", "style": { "fontFamily": "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, sans-serif", "fontWeight": "normal" } }, "labels": {} }, "fill": { "opacity": 1, "colors": ["#3f8cf4", "#2961a7", "#ed7333", "#5e40d2", "#7ec2fa", "#9dd64c", "#9dd64c"] }, "tooltip": { "y": {} }, "colors": ["#3f8cf4", "#2961a7", "#ed7333", "#5e40d2", "#7ec2fa", "#9dd64c", "#9dd64c"]
            }
        }


        const tableColumns = [
            {
                Header: "Employee Name",
                accessor: "name",
                className: "text-center"
            },
            {
                Header: "Department",
                accessor: "department",
                className: "text-center"
            },
            {
                Header: "Qty",
                accessor: "count",
                className: "text-center",
                style: { 'whiteSpace': 'unset' },
                maxWidth: 60,
            },
        ];


        const defaultSorted = [
            {
                id: 'qty',
                desc: true,
            },
        ];

        return (
                      <Container fluid className="main-content-container px-4" style={{ backgroundColor: 'white' }}>

                {!this.state.openIdea ?
                    (
                        <div>
                            <Row noGutters className="page-header py-4">
                                <PageTitle title={'Dashboard'} subtitle={'Implement Workflow'} className="text-sm-left mb-3" />
                            </Row>

                            <Row noGutters className='w-full' style={{ marginBottom: 40 }}>
                                <Card style={{ width: '100%'}} small className="mb-3 w-full">
                                    <CardBody className="w-full">
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                                            <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                                                {t('Opportunity Stage')}
                                            </p>
                                        </div>
                                        <BarChart data={chartData} />
                                    </CardBody>
                                </Card>
                            </Row>

                            <h4 className="m-auto" style={{ fontWeight: 600, color: '#303030' }}>{t(" Open Opportunities")}</h4>

                            <p>You are currently looking at opportunities assigned to you as an owner or coach; assigned to someone who reports to you and is currently an owner or coach of an opportunity; or, if you are a Department leader, all opportunities submitted and approved to benefit your department.</p>

                            <IdeaFilterManagerLite
                                onCategoryChange={this.onCategoryChange.bind(this)}
                                onViewAsChange={this.onViewAsChange.bind(this)}
                                onDepartmentChange={this.onDepartmentChange.bind(this)}
                                onStatusChange={this.onStatusChange.bind(this)}
                                onTypeChange={this.onTypeChange.bind(this)}
                                onQuestionChange={this.onQuestionChange.bind(this)}
                                onEvalCommChange={this.onEvalCommChange.bind(this)}
                                onTransferCommitteeChange={this.onTransferCommitteeChange.bind(this)}
                                reset={this.state.resetFilters}
                            />

                            <Row>
                                <Col className="mb-4">
                                    <ImplementDashboardTable handleViewClick={this.handleViewClick.bind(this)} viewAs={this.state.viewAs} category={this.state.categoryName} department={this.state.departmentName} status={this.state.statusName} ideaType={this.state.ideaType} filterQuestion={this.state.ideaQuestion} onEditActionPress={this.toggle.bind(this)} onViewIdeaPress={this.showIdea.bind(this)} onDeleteIdeaPress={this.deleteIdea.bind(this)} evalComm={this.state.evalComm} />
                                </Col>
                            </Row>

                            <Row>
                                <Col lg="6" sm="12" className="mb-4">
                                    <Card className="cardMinHeight">
                                        <CardBody>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                                                <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                                                    {t('Owners')}
                                                </p>
                                            </div>
                                            <div className={'transparent-responsive-card transparent'}>
                                                <ResponsiveTable
                                                    columns={tableColumns}
                                                    data={this.state.ownerData}
                                                    pageSize={5}
                                                    showPageSizeOptions={false}
                                                    resizable={false}
                                                    defaultSorted={defaultSorted}
                                                />
                                            </div>
                                        </CardBody>

                                    </Card>
                                </Col>

                                <Col lg="6" sm="12" className="mb-4">
                                    <Card className="cardMinHeight">
                                        <CardBody>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                                                <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                                                    {t('Coaches')}
                                                </p>
                                            </div>
                                            <div className={'transparent-responsive-card transparent'}>
                                                <ResponsiveTable
                                                    columns={tableColumns}
                                                    data={this.state.coachesData}
                                                    pageSize={5}
                                                    showPageSizeOptions={false}
                                                    resizable={false}
                                                    defaultSorted={defaultSorted}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )
                    :
                    (
                        <ImplementIdea
                            openDuplicateForm={() => this.openDuplicateForm()}
                            openImplementedForm={() => this.openImplementedForm()}
                            openNotImplementedForm={() => this.openNotImplementedForm()}
                            currIdea={this.state.currIdea}
                            updatePercentage={(percentage) => this.updatePercentage(percentage)}
                            updateCurrIdea={() => this.updateCurrIdea()}
                            closeModal={() => this.setState({ openIdea: false })}
                            showA3={(canShow) => this.setState({ showA3: canShow })}
                        ></ImplementIdea>
                    )}
            </Container>

        )
    }
}

export default withTranslation()(ImplementDashboard);

