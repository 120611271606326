import moment from 'moment';
import Parse from 'parse';
import React from "react";
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  FormInput,
  FormTextarea,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Tooltip
} from "shards-react";

import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

import { object } from "prop-types";
import ReactLoading from 'react-loading';
import ExecutionSelectNew from "./ExecutionSelectNew";

import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as GreenIcon } from "../../images/green_icon.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as RedIcon } from "../../images/red_icon.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";
// fill="#157ffb"

// New
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";
import { ReactComponent as DefaultIcon } from '../../images/default_icon.svg';

// New Selected
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";
import Switch from "./Switch.js";

// Updated Icons
import { ReactComponent as DenyImage } from '../../images/Icons_Idle_08_Deny.svg';
import { ReactComponent as SaveImage } from '../../images/Icons_Idle_09_Save.svg';
import { ReactComponent as TransferImage } from '../../images/Icons_Idle_10_Transfer.svg';
import { ReactComponent as ProjectImage } from '../../images/Icons_Idle_11_Project.svg';
import { ReactComponent as QuestionImage } from '../../images/Icons_Idle_12_Question.svg';

// Updated Icons Selected
import { ReactComponent as DenyImageSelected } from '../../images/Icons_Selected_08_Deny.svg';
import { ReactComponent as SaveImageSelected } from '../../images/Icons_Selected_09_Save.svg';
import { ReactComponent as TransferImageSelected } from '../../images/Icons_Selected_10_Transfer.svg';
import { ReactComponent as ProjectImageSelected } from '../../images/Icons_Selected_11_Project.svg';
import { ReactComponent as QuestionImageSelected } from '../../images/Icons_Selected_12_Question.svg';

import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";

import IdeaStatusSelect from "./IdeaStatusSelect";

import ReactDOMServer from 'react-dom/server';
import { withTranslation } from 'react-i18next';
import { getConfiguration } from '../../services/configurationService.js';
import { fetchOpportunityById, markDuplicate, submitEvaluation } from '../../services/evaluateService.js';
import { authUser } from '../../services/userService.js';
import MergeIdeasModal from '../administration/MergeIdeasModal.js';
import MoreInformationModal from "../administration/MoreInformationModal";
import PMOModal from "../administration/PMOModal";
import SaveForLaterModal from "../administration/SaveForLatermodal";
import TransferCommitteeModal from "../administration/TransferCommitteeModal";
import CustomFileWithProgressUpload from "../components-overview/CustomFileWithProgressUpload";
import IdeaAttachmentModal from "./IdeaAttachmentModal.js";

class IdeaViewCardNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      ideaProponentHistory: [],
      duplicateSimilarOpportunity: null,
      duplicateOriginalOpportunity: null,
      ideaQuestions: [],
      answers: [],
      category: null,
      department: null,
      loadingSave: false,
      date: '',
      categoryQuestions: [],
      filterQuestions: [],
      selectedFilterQ: [],
      filterQAnswers: [],
      categoryQAnswers: [],
      ideaDescription: null,
      file: null,
      buttonNext: true,
      descriptionValid: '',
      remainingCharacters: 250,
      visible: true,
      filterVisible: false,
      filterQuestionsVisible: false,
      ideaQuestionsVisible: false,
      hideNextButton: true,
      userName: 'User Name',
      sectionTitle: '',
      formButtonTitle: 'Continuar',
      ideaNumber: '#00008',
      status: '',
      executionResObj: object,
      selectedBenefit: '',
      selectedResult: '',
      money: '',
      selectedImprovement: '',
      selectedImpact: '',
      selectedCoachBackup: '',
      isRecognized: '',
      page: 2,
      responseInfo: false,
      responseInfo2: false,
      selectedStatus: ''.length,
      expectedReturn: '',
      timeUnit: '',
      timeUnitVal: '',
      executionRes: '',
      coachRes: '',
      recurringImpact: false,
      comment: '',
      needsEconomicImpact: false,
      attachmentModalOpen: false,
      hasTeam: false,
      rfiOpen: false,
      transferCommitteeOpen: false,
      pmoOpen: false,
      showEvalFields: true,
      rfiInfo: '',
      showRfiField: false,
      showRfiAnswerField: false,
      tcInfo: '',
      showTcField: false,
      showTcAnswerField: false,
      pmoInfo: '',
      showPmoField: false,
      showPmoAnswerField: false,
      pmoSelection: false,
      showLoading: false,
      showEvalCriteria: false,
      userEmail: '',
      evaluationCriteria: '',
      sflOpen: false,
      sflInfo: '',
      sflSelection: '',
      showSflInfo: false,
      showSflField: false,
      evalCommHistory: [],
      evaluatorName: '',
      newComm: '',
      uploadProgress: 100,
      mergeIdeasOpen: false,
      markedAsDuplicate: false,
      originalOpportunity: '',
      similarOpportunity: '',
      attachments: [],
      selectedOpportunity: this.props.ideaItem,
      opportunity: {},
      isLoading: true,
      changeBtnStatus: this.props.changeBtnStatus
    }

    this.change = this.change.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setIdeaDescription = this.setIdeaDescription.bind(this);
    this.showNext = this.showNext.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.saveIdea = this.saveIdea.bind(this);
    this.changeMoney = this.changeMoney.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.setEvalStatus = this.setEvalStatus.bind(this);
    this.setExpectedReturn = this.setExpectedReturn.bind(this);
    this.setTimeUnit = this.setTimeUnit.bind(this);
    this.getOpportunityInfo = this.getOpportunityInfo.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (prevProps.canSubmit !== this.props.canSubmit) {
      this.submitEvaluation()
    }


    if (prevProps.ideaStage == 0 && this.props.ideaStage == 1) {

      if (this.state.expectedReturn && this.state.timeUnit) {
        this.props.changeStatus(true)
      }

      if (this.state.needsEconomicImpact == false) {
        this.props.changeStatus(true)
      }

      if (this.state.selectedStatus) {

        this.props.changeStatus(true)
      } else {
        this.props.changeStatus(false)
      }

    }

    if (prevProps.ideaStage == 1 && this.props.ideaStage == 2) {

      if (this.state.selectedStatus == "Do not Pursue" || this.state.selectedStatus == "Save for Later") {
        this.props.changeStatus(true)
      }

      if (this.state.executionRes) {
        this.props.changeStatus(true)
      }
    }

    if (prevProps.ideaStage == 2 && this.props.ideaStage == 1) {

      if (this.state.expectedReturn && this.state.timeUnit) {
        this.props.changeStatus(true)
      }

      if (this.state.needsEconomicImpact == false) {
        this.props.changeStatus(true)
      }


    }

    if (prevProps.ideaStage == 3 && this.props.ideaStage == 2) {

      if (this.state.executionRes) {
        this.props.changeStatus(true)
      }

    }
  }

  

  async getOpportunityInfo(id) {
    const opportunity = await fetchOpportunityById(id);
    this.setState({ opportunity })
  }


  async componentDidMount() {
    const ideaItem = await this.getIdeaItem();
    this.setState({ ideaItem, isLoading: false });
    this.getEvaluationCriteria()
    this.fetchDuplicateOpportunity(ideaItem);
  }



  fetchDuplicateOpportunity = async (ideaItem) => {
    if(!ideaItem.merged) return;
    const similarId = ideaItem.merged.similar.id
    const originalId = ideaItem.merged.original.id

    const similarOpportunity = await fetchOpportunityById(similarId);
    const originalOpportunity = await fetchOpportunityById(originalId);

    this.setState({ duplicateSimilarOpportunity: similarOpportunity, duplicateOriginalOpportunity:originalOpportunity });

  };


  getEvaluationCriteria = async () => {
    const evaluationCriteria = await getConfiguration();
    const opportunityApprovalCriteria = evaluationCriteria.opportunityApprovalCriteria;
    if (opportunityApprovalCriteria) {
      this.setState({ evaluationCriteria: opportunityApprovalCriteria })
    }
  }

  getDate() {
    this.setState({
      date: new Date()
    });
  }

  getOpportunityInfo = async (idea, type) => {
    if (!idea) return;

    try {
      const result = await idea.fetch();
      if (type === 'originalOpportunity') {
        this.setState({ originalOpportunity: result });
      } else {
        this.setState({ similarOpportunity: result });
      }

      const file = result.file;
      if (file) {
        this.setState((prevState) => ({
          attachments: [...prevState.attachments, { id: result.id, name: `Opportunity #${result.num}`, file: file, opportunity: result }]
        }));
      }

      const ogFile = this.props.ideaItem.file;
      if (ogFile) {
        this.setState((prevState) => ({
          attachments: [...prevState.attachments, { id: this.props.ideaItem.id, name: `New File`, file: ogFile, opportunity: this.props.ideaItem }]
        }));
      }

    } catch (error) {
      console.error('Error while fetching opportunity', error);
    }
  };

  async getUserName() {
    const user = authUser();


    const firstName = user.firstName;
    const lastName = user.lastName;
    const fullName = firstName + ' ' + lastName;

    this.setState({
      userName: fullName,
      userEmail: user.email
    });
  }

  changeRfiInfo = (info) => {
    this.setState({ rfiInfo: info, rfiOpen: false, showRfiField: true, showTcField: false, showPmoField: false, showSflField: false })
  }

  changeTcInfo = (info) => {
    this.setState({ tcInfo: info, transferCommitteeOpen: false, showTcField: true, showRfiField: false, showPmoField: false, showSflField: false })
  }

  changeCommInfo = (info) => {
    this.setState({ newComm: info })
  }

  changePmoInfo = (info) => {
    this.setState({ pmoInfo: info, pmoOpen: false, showPmoField: true, showRfiField: false, showTcField: false, showSflField: false, })
  }

  changeSflInfo = (info) => {
    this.setState({ sflInfo: info, sflOpen: false, showSflField: true, showPmoField: false, showRfiField: false, showTcField: false })
  }

  changeSelection = (info) => {
    this.setState({ pmoSelection: info })
  }

  changeSflSelection = (info) => {
    this.setState({ sflSelection: info })
  }

  editRfi = () => {
    this.setState({ rfiOpen: true })
  }

  editTc = () => {
    this.setState({ transferCommitteeOpen: true })
  }

  editPmo = () => {
    this.setState({ pmoOpen: true })
  }

  editSfl = () => {
    this.setState({ sflOpen: true })
  }

  handleSubmit() {
    const { formButtonTitle } = this.state;

    if (formButtonTitle == "Continuar") {
      this.showNext();
    } else {
      var isInvalid = false
      this.state.answers.forEach((item, idx) => {
        //  
        if (item.required && !item.answer) {
          isInvalid = true
        }
      });

      if (isInvalid) {
        alert("Por favor conteste todas las preguntas requeridas.");
      } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

  showNext() {
    const isShowingForm = this.state.visible;
    const isShowingFilter = this.state.filterVisible;
    const isShowingFilterQuestions = this.state.filterQuestionsVisible;
    const isShowingQuestions = this.state.ideaQuestionsVisible;

    if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

      const { category, department, descriptionValid } = this.state;

      if (!category || !department || !descriptionValid) {
        alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
      } else {
        this.setState({
          visible: !isShowingForm,
          filterVisible: !isShowingFilter,
          buttonState: false,
          hideNextButton: true,
        });
      }
    } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
      this.setState({
        hideNextButton: false,
        filterVisible: !isShowingFilter,
        filterQuestionsVisible: !isShowingFilterQuestions,
        buttonState: false,
      });
    } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
      var allAnswered = false;

      var isInvalid = false
      this.state.filterQAnswers.forEach((item, idx) => {
        //  
        if (!item.answer) {
          isInvalid = true
        }
      });

      if (isInvalid) {
        alert("Por favor conteste todas las preguntas.");
      } else {
        this.setState({
          filterQuestionsVisible: !isShowingFilterQuestions,
          ideaQuestionsVisible: !isShowingQuestions,
          buttonState: false,
          formButtonTitle: 'Someter',
        });
      }
    } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
      //  
      var isInvalid = false
      this.state.answers.forEach((item, idx) => {
        //  
        if (item.required && !item.answer) {
          isInvalid = true
        }
      });

      if (isInvalid) {
        alert("Please answer all required questions.");
      } else {
        alert('¡Congrats! Thanks for submitting your idea.',
          this.resetIdeaForm());
      }
    }
  }

  resetIdeaForm() {
    window.location.reload();
  }

  fetchIdeaProponentHistory() {
    const className = "IdeaProponentHistory";
    const ItemClass = Parse.Object.extend(className);
    const query = new Parse.Query(ItemClass);
    query.equalTo("idea", this.props.ideaItem);
    query.ascending("createdAt");

    query.limit(999999999).find()
      .then((results) => {
        this.setState({
          ideaProponentHistory: results
        });
      }, (error) => {
        this.setState({
          ideaProponentHistory: []
        });
      });
  }

  fetchNewData() {
    const className = "IdeaDepartment";
    var ItemClass = Parse.Object.extend(className);
    var query = new Parse.Query(ItemClass);

    query.find()
      .then((results) => {
        this.setState({
          data: results
        });
        //  
      }, (error) => {
        this.setState({
          data: []
        });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
  }

  fetchCategoryData() {
  }

  fetchQuestions() {
  }

  fetchFilterQuestions() {
  }

  handleFilterChange(newFilter) {
    const newCategory = newFilter;
    const { filterQuestions, selectedFilterQ } = this.state;
    //  
    //  

    var filteredData;
    if (newCategory !== 'Todas') {
      filteredData = filterQuestions.filter(item => item.filter === newCategory);
    } else {
      filteredData = filterQuestions;
    }

    this.setState({
      selectedFilterQ: filteredData
    }, this.addFilterAnswer(filteredData));
    //  
    this.showNext();
  }

  handleCategoryChange(selectedCategory) {
    const newCategory = selectedCategory;
    const { ideaQuestions } = this.state;

    var filteredData = ideaQuestions.filter(item => item.category === newCategory);

    this.setState({
      categoryQuestions: filteredData
    }, this.addAnswer(filteredData));
  }

  onSubmit = e => {
    e.preventDefault();
    alert('Form submitted');
  };

  saveIdea() {

  }

  resetForm() {
    this.setState({ proponent: '', department: '', category: '', ideaDescription: '', date: new Date(), file: '', remainingCharacters: 250, descriptionValid: '' });
  }

  change(event) {
    // this.setState({category: event.target.value});
    this.setState({ department: event.target.value });
    //  
  }

  setCategory(categoryName) {
    this.setState({
      category: categoryName
    })

    this.handleCategoryChange(categoryName)
    //  
  }

  setFilter(filterName) {
    //  
    // this.setState({sectionTitle: (filterName === "innovacion")?"INNOVACION":"RESOLUCION DE PROBLEMAS"});
    this.handleFilterChange(filterName);
  }

  clickedPrint() {

  }

  setDate(ideaDate) {
    this.setState({
      date: ideaDate
    })
    //  
  }

  filterQuestionAnswerChange(event, idx) {
    //  
    //  
    // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
    this.state.filterQAnswers[idx].answer = event.target.value;
    //  
    // const newArray = this.state.filterQAnswers
    // this.setState({filterQAnswers: newArray},  
    // this.setState({department: event.target.value});
    //  
  }

  questionAnswerChange(event, idx) {
    //  
    //  
    // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
    this.state.answers[idx].answer = event.target.value;
    //  
    // const newArray = this.state.filterQAnswers
    // this.setState({filterQAnswers: newArray},  
    // this.setState({department: event.target.value});
    //  
  }

  setIdeaDescription(event) {
    const description = event.target.value;
    const maxCharacters = 250;
    const charCount = maxCharacters - description.length
    var isValid = null;

    if (charCount < 0 && description.length > 0) {
      isValid = false
    } else if (charCount > 0 && description.length > 0) {
      isValid = true
    } else {
      isValid = null
    }

    //  
    this.setState({
      descriptionValid: isValid,
      ideaDescription: description,
      remainingCharacters: charCount
    })
    //  
  }

  addAnswer(filteredQuestions) {
    filteredQuestions.map((item, idx) => (
      //  
      this.setState((prevState) => ({
        answers: [...prevState.answers, { question: item.question, answer: "", required: item.required }],
      }))
    ))
    //  
  }

  addFilterAnswer(filteredData) {
    //  

    var newItems = [];
    filteredData.forEach((item, idx) => {
      newItems.push({ question: item.question, answer: '' });
    })

    this.setState({ filterQAnswers: newItems }, () => {
      //  
    });
  }

  async submitEvaluation() {
    const {
      newComm,
      expectedReturn,
      selectedStatus,
      coachRes,
      executionRes,
      rfiInfo,
      pmoInfo,
      pmoSelection,
      sflInfo,
      sflSelection,
      recurringImpact,
      timeUnitVal,
      file,
      tcInfo
    } = this.state;

    let { comment } = this.state;
    let likelyToApproveInFuture;
    let isAlignedWithBusinessGoals;

    let newStatus = '';
    switch (selectedStatus) {
      case 'Request\ninformation':
        newStatus = 'Response Needed';
        break;
      case "Save for Later":
        newStatus = 'Save for Later';
        comment = sflInfo
        likelyToApproveInFuture = sflSelection
        break;
      case "Do not Pursue":
        newStatus = 'Do not Pursue';
        break;
      case "Approve":
        newStatus = 'Approved';
        break;
      case "Project Opportunity":
        newStatus = 'Project Opportunity';
        isAlignedWithBusinessGoals = pmoSelection
        comment = pmoInfo
        break;
      case "Transfer\nCommittee":
        newStatus = 'Transfer Committee';
        comment = tcInfo
        break;
      default:
        newStatus = '_SIN ACTION_';
    }

    const data = {
      opportunityId: this.props.ideaItem.id,
      action: newStatus,
      likelyToApproveInFuture,
      isAlignedWithBusinessGoals,
      reason: comment || "No reason provided",
      question: newStatus === 'Response Needed' ? rfiInfo : undefined,
      fileId: file ? file.fileId : undefined,
      economicImpactAmount: expectedReturn ? parseInt(expectedReturn) : undefined,
      impactTime: timeUnitVal ? timeUnitVal.value : undefined,
      recurringImpact: recurringImpact || undefined,
      committeeId: newComm ? newComm.value.id : undefined,
      implementationLeaderId: executionRes ? executionRes.value.id : undefined,
      coachIds: coachRes ? coachRes.map(item => item.value.id).join(",") : undefined
    };

    try {
      const evaluationResponse = await submitEvaluation(data);
      if (evaluationResponse.message) {
        this.props.setFinishedSaving(data)
        this.setState({ loadingSave: false });
      }

    } catch (error) {
      if (error.response.data) {
        if (error.response.data.error)
          alert(error.response.data.error.message);
        else if (error.response.data.message)
          alert(error.response.data.message);
      } else
        alert(error);

      console.error('Error submitting evaluation:', error);
    }
  }



  async saveIdeaItem() {
    // if(this.state.loadingSave) return;

    // this.setState({loadingSave: true});

    const { setFinishedSaving } = this.props;
    // const { selectedStatus } = this.state;
    // ideaItem.set("customUpdatedAt", new Date());

    // const ideaObjectJson = ideaItem.toJSON();

    // const EvaluationHistoryItem = Parse.Object.extend("EvaluationHistory");
    // const historyIdeaItem = new EvaluationHistoryItem();

    // const newCommitteeObj = ideaObjectJson.newCommitteeObj ? await (new Parse.Query("EvaluationCommittee").get(ideaObjectJson.newCommitteeObj.objectId)) : null;

    // const currentUser = Parse.User.current();
    // const userJson = currentUser.toJSON();

    // ideaObjectJson.ideaReference = ideaObjectJson.objectId

    // delete ideaObjectJson.objectId;
    // delete ideaObjectJson.comments;
    // delete ideaObjectJson.date;
    // delete ideaObjectJson.file;
    // delete ideaObjectJson.proponentObj;
    // delete ideaObjectJson.responsible;
    // delete ideaObjectJson.createdAt;
    // delete ideaObjectJson.updatedAt;
    // delete userJson.sessionToken; 

    // ideaObjectJson.evaluatedAt = new Date();
    // ideaObjectJson.newCommitteeObj = newCommitteeObj;
    // ideaObjectJson.actionBy = userJson;

    // delete userJson.sessionToken; 
    // delete ideaObjectJson.potentialDuplicates; 
    // delete ideaObjectJson.mergedOpportunityId;
    // delete ideaObjectJson.notDuplicates;
    // delete ideaObjectJson.similarIdea;
    // delete ideaObjectJson.duplicateInfo; 
    // delete ideaObjectJson.duplicateOriginalOpportunity; 
    // delete ideaObjectJson.duplicateSimilarOpportunity; 
    // ideaObjectJson.actionBy = userJson;

    // historyIdeaItem.set(ideaObjectJson)
    // historyIdeaItem.set("customUpdatedAt", new Date());


    // Parse.Object.saveAll([ideaItem, historyIdeaItem], {useMasterKey: true}).then(async () => {
    //   if(ideaItem && ideaItem.id) {
    //     await fetch(`https://services.murmuratto.com/update-file/${process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo'}/Idea/evaluationFile/${ideaItem.id}`)
    //   }

    //   if(historyIdeaItem.get('status') === 'Transfer Committee'){
    //     if(historyIdeaItem.get('newCommitteeObj')){
    //       await sendEmail({
    //         typeEmail: 'M_NE_28', // transfer committee
    //         client: process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo',
    //         subject: 'Opportunity Transfer Notificacion',
    //         userId: currentUser.id,
    //         ideaId: ideaItem.id,
    //         committeeId: historyIdeaItem.get('newCommitteeObj').id,
    //       })
    //     }
    //   }

    //   if(ideaItem.get('status') === 'Approved'){
    //     if(ideaItem.get('responsible')){
    //       await sendEmail({
    //         typeEmail: 'M_NE_01',
    //         client: process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo',
    //         subject: 'Assigned to you',
    //         userId: currentUser.id,
    //         ideaId: ideaItem.id,
    //       })
    //     }

    //     //M_NE_10
    //     await sendEmail({
    //       typeEmail: 'M_NE_10', // approved idea
    //       client: process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo',
    //       subject: 'Good news! Your opportunity has been approved!',
    //       userId: currentUser.id,
    //       ideaId: ideaItem.id,
    //     });
    //   }

    //   if (this.state.status == 'Otro') {
    //     alert('Su evaluacion ha sido sometida. ¡Gracias!', setFinishedSaving());
    //   } else {
    //     setFinishedSaving()
    //   }

    //   if (ideaItem.get("coachEmails")) {
    //     await this.sendCoachAssignmentEmail(ideaItem)
    //   }

    //   if (ideaItem.get("responsibleEmail")) {
    //     await this.sendEvaluationEmail(ideaItem)
    //   }

    //   if (selectedStatus == 'Transfer\nCommittee') {
    //     await this.sendTransferCommitteeEmail(ideaItem)
    //   }

    //   if (this.state.rfiInfo) {
    //     await this.sendRfiEmail(ideaItem)
    //   }
    alert('Su evaluacion ha sido sometida. ¡Gracias!', setFinishedSaving());
    this.setState({ loadingSave: false });

  }

  changeStatus(selection) {
    this.setState({ status: selection });

  }

  changeEvaluationResponse() {
    const responseObj = {
      status: '',
      economicImpact: '',
      timeFrame: '',
      recurringImpact: false,
      comments: '',
      ideaOwner: '',
      ideaCoach: ''
    }
  }

  async getHumanResourceUsers() {
    var query = new Parse.Query(Parse.User);
    query.equalTo("humanResources", true);
    const results = await query.find();

    return results
  }

  async setVerificationNotifications() {
    const allUsers = await this.getHumanResourceUsers();
  }

  async setNotifications() {
    const responsibleMsg = '¡Congrats! Your idea has been verified.';

    var ideaItem = this.props.ideaItem;

    var responsibleUser = ideaItem.responsible;

    var responsibleNotifications = responsibleUser.get("notificationMessages");
    responsibleNotifications.unshift(responsibleMsg);

    responsibleUser.increment("notificationCount");
    responsibleUser.set("notificationMessages", responsibleNotifications);
    responsibleUser.set("customUpdatedAt", new Date());

    var users = new Parse.Query("Users");

    if (this.state.isRecognized) {
      await Parse.Object.saveAll(users, { useMasterKey: true }).then(() => this.setVerificationNotifications())
        .catch(error => {

        });
    } else {
      await Parse.Object.saveAll(users, { useMasterKey: true })
        .catch(error => {

        });
    }
  }

  changeBenefit(response) {
    this.setState({
      selectedBenefit: response
    });
  }

  changeResults(response) {
    this.setState({
      selectedResult: response
    });
  }

  changeImprovement(response) {
    this.setState({
      selectedImprovement: response
    });
  }

  changeMoney(response) {
    this.setState({
      money: response
    });
  }

  changeImpact(response) {
    this.setState({
      selectedImpact: response
    });
  }

  changeBackup(response) {
    this.setState({
      selectedCoachBackup: response
    });
  }

  changeMoney(response) {
    const inputMoney = parseFloat(response.target.value);

    this.setState({
      money: inputMoney
    })
  }

  changeRecognition(response) {
    this.setState({
      isRecognized: response
    });
  }


  getDate(date) {
    return moment(date).format('DD MMMM YYYY');
  }

  getIcon(name, fillColor) {
    const { selectionValue, selectedCategoryName, page } = this.state;
    const newIcons = [
      {
        normal: <UrgentImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <UrgentImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <ProductivityImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <ProductivityImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <CheckmarkImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <CheckmarkImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <TrophyImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <TrophyImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <Shield2Image className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <Shield2ImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <DollarSignImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <DollarSignImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <NumberOneImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <NumberOneImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <UrgentImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <UrgentImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <DenyImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <DenyImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <TransferImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <TransferImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <ProjectImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <ProjectImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <QuestionImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <QuestionImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <SaveImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <SaveImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <CheckmarkImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <CheckmarkImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
    ]

    switch (name) {
      case 'HandImage':
        return <HandImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'ShieldImage':
        return <ShieldImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'ChartImage':
        return <ChartImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'TeamImage':
        return <TeamImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'DollarImage':
        return <DollarImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'ClockImage':
        return <TimeImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      //New Icons
      case 'Urgent':
        return newIcons[0].selected;
      case 'Productivity':
        return newIcons[1].selected;
      case 'Checkmark':
        return newIcons[2].selected;
      case 'Trophy':
        return newIcons[3].selected;
      case 'Shield Image':
        return newIcons[4].selected;
      case 'Dollar':
        return newIcons[5].selected;
      case 'Dollar Sign':
        return newIcons[5].selected;
      case 'Number One':
        return newIcons[6].selected;
      case 'Approve':
        return newIcons[13].selected;
      case 'Do not Pursue':
        return newIcons[8].selected;
      case 'Save for Later':
        return newIcons[12].selected;
      case 'Request information':
        return newIcons[11].selected;
      case 'Request\ninformation':
        return newIcons[11].selected;
      case 'Project Opportunity':
        return newIcons[10].selected;
      case 'Transfer Committee':
        return newIcons[9].selected;
      case 'Transfer\nCommittee':
        return newIcons[9].selected;
      default:
        return <DefaultIcon className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
    }
  }

  getIconDescription(name) {
    const { t } = this.props;

    switch (name) {
      case 'Approve':
        return t("APPROVE_MSG");
      case 'Do not Pursue':
        return t("DO_NOT_PURSUE_MSG");
      case 'Save for Later':
        return t("SAVE_FOR_LATER_MSG");
      case 'Request information':
        return t("REQUEST_INFORMATION_MSG");
      case 'Request\ninformation':
        return t("REQUEST_INFORMATION_MSG");
      case 'Project Opportunity':
        return t("PROJECT_IDEA_MSG");
      case 'Transfer Committee':
        return t("TRANSFER_COMMITTEE_MSG");
      case 'Transfer\nCommittee':
        return t("TRANSFER_COMMITTEE_MSG");
      default:
        return "No description available at the moment."//<SelectIdeaImage className="mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
    }
  }

  toggle() {
    this.setState({
      responseInfo: !this.state.responseInfo
    });
  }

  toggleTwo() {
    this.setState({
      responseInfo2: !this.state.responseInfo2
    });
  }

  setEvalStatus(status, index) {

    var shouldOpenRfiModal = false
    var showEvalFields = true
    var shouldOpenSflModal = false

    var shouldOpenTcModal = false
    var shouldOpenPmoModal = false

    var shouldShowSfl = false

    if (status === "Request\ninformation") {
      shouldOpenRfiModal = true
      showEvalFields = false
    }

    if (status === 'Save for Later') {
      shouldOpenSflModal = true
      showEvalFields = false
    }

    if (status === "Transfer\nCommittee") {
      shouldOpenTcModal = true
      showEvalFields = false
    }

    if (status === "Project Opportunity") {
      shouldOpenPmoModal = true
      showEvalFields = false
    }

    if (status === "Approve" || status === "Do not Pursue" || status === "Save for Later") {
      this.setState({
        showRfiField: false,
        showTcField: false,
        showPmoField: false,
      })
    }

    if (status == "Approve" || status == "Do not Pursue") {
      shouldShowSfl = false
    }

    if (status != "Approve") {
      this.props.setNoMoreSteps(true)
    } else {
      this.props.setNoMoreSteps(false)
    }

    this.props.changeStatus(true)

    this.setState({
      showEvalFields: showEvalFields,
      sflOpen: shouldOpenSflModal,
      rfiOpen: shouldOpenRfiModal,
      pmoOpen: shouldOpenPmoModal,
      transferCommitteeOpen: shouldOpenTcModal,
      selectedStatus: status,
      selectedStatusVal: status,
      showSflField: shouldShowSfl
    })
  }

  setExpectedReturn(event) {
    const amount = event.target.value;
    const correctAmount = amount.replace(/\D/g, "");


    this.setState({
      expectedReturn: correctAmount,
    });


    if (amount && this.state.timeUnit && this.state.selectedStatus) {
      this.props.changeStatus(true);
    } else {

      this.props.changeStatus(false);
    }
  }


  setTimeUnit(unit) {

    this.setState({ timeUnit: unit.label, timeUnitVal: unit }, () => {

      if (this.state.expectedReturn && unit.label && this.state.selectedStatus) {
        this.props.changeStatus(true);
      } else {

        this.props.changeStatus(false);
      }
    });
  }

  changeResponsible(res, idx) {
    this.setState({
      executionRes: res,
    });

    if (res) this.props.changeStatus(true)
  }

  changeCoach(res, idx) {
    this.setState({
      coachRes: res,
    });

  }

  commentChangeField(res) {
    const comment = res.target.value
    this.setState({
      comment: comment
    })
  }

  downloadFile(file) {
    if (file != null) {
      const newWindow = window.open(file, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } else {
      alert('No file found...');
    }
  }



  showEvalCriteriaModal = () => {
    this.setState({ showEvalCriteria: true })
  }

  getTeamMembers = () => {
    const teamMemberObjs = this.props.ideaItem.teamMembers

    if (teamMemberObjs) {
      return this.props.ideaItem.teamMembers.join(" • ");
    } else {
      return "N/A";
    }
  }


  getEvaluationHistory = async () => {
    const EvaluationHistory = Parse.Object.extend('EvaluationHistory');
    const query = new Parse.Query(EvaluationHistory);
    query.equalTo('ideaReference', this.props.ideaItem.id);
    let evalCommHistory = await query.find({ useMasterKey: true });

    const promises = evalCommHistory.map(async (item) => {
      if (item.get("actionBy")) {
        const actionByParse = item.get("actionBy");
        const fullName = `${actionByParse.firstName} ${actionByParse.lastName}`;
        item.set('userFullName', fullName);
      } else {
        const User = Parse.Object.extend("User");
        const query = new Parse.Query(User);
        query.doesNotExist("deletedAt").equalTo("email", item.get("evaluatedBy"));

        const user = await query.first({ useMasterKey: true });
        if (user) {
          const firstName = user.get("firstName");
          const lastName = user.get("lastName");
          const fullName = `${firstName} ${lastName}`;
          item.set('userFullName', fullName);
        }
      }
      return item;
    });

    const resolvedEvalCommHistory = await Promise.all(promises);
    this.setState({ evalCommHistory: resolvedEvalCommHistory });
  }

  getUserFirstNameLastName = (email) => {
    const User = Parse.Object.extend("User");
    const query = new Parse.Query(User);
    query.doesNotExist("deletedAt").equalTo("email", email);

    return query.find({ useMasterKey: true })
      .then(results => {
        if (results.length > 0) {
          const user = results[0];
          const firstName = user.get("firstName");
          const lastName = user.get("lastName");

          return `${firstName} ${lastName}`;
        } else {
          return email;
        }
      })
      .catch(error => {
        console.error('Error while retrieving User objects:', error);
        return email;
      });
  }

  handleCloseTC = () => {
    this.setState({ transferCommitteeOpen: false, selectedStatus: '', showSflField: false })
    this.props.changeStatus(false)
  }

  handleCloseSFL = () => {
    this.setState({ sflOpen: false, selectedStatus: '' })
    this.props.changeStatus(false)
  }

  handleClosePMO = () => {
    this.setState({ pmoOpen: false, selectedStatus: '', showSflField: false })
    this.props.changeStatus(false)
  }

  closeModal = () => {
    this.setState({ text: '', rfiOpen: false, selectedStatus: '', showSflField: false })
    this.props.changeStatus(false)
  }

  isImage(fileName) {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    return allowedExtensions.test(fileName);
  }

  changeIdeaStageBack = () => {
    this.props.history.goBack();
  }

  changeIdeaStage = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to mark this opportunity as not a duplicate?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCloseButton: true,
      icon: null // Remueve el ícono
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ markedAsDuplicate: true, isDuplicate: false });
      }
    });
  }

  goToOpportunity = (id) => {
    const toUrl = `/evaluate-${id}?back=${this.props.ideaItem.id}`
    window.location = toUrl
  }

  async getIdeaItem() {
    const hasSubmittal = 'submittal' in this.props.ideaItem;
    const opportunity = await fetchOpportunityById(this.props.ideaItem.id);
    return hasSubmittal ? this.props.ideaItem : opportunity;
  }


  render() {
    const { ideaItem, isLoading, duplicateSimilarOpportunity, duplicateOriginalOpportunity, coachRes, expectedReturn, visible, executionRes } = this.state
    const { ideaStage, isDuplicate, t } = this.props;
    const formVisibilityState = visible ? 'block' : 'none';

    if (isLoading) {
      return <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30em' }}>
        <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
      </div>
    }

    const ideaDate = ideaItem.submittal.submittedAt;

    const timingWording = ideaItem.submittal.responseStatus
    let numberQuestion = 1
    const parsedDate = this.getDate(ideaDate)
    const nowDate = this.getDate(Date())

    const customStyles = {
      control: base => ({
        ...base,
        height: 35,
        minHeight: 35
      })
    };

    const myIcon = ideaItem && ideaItem.category.icon
    const categoryTitle = ideaItem && ideaItem.category.name
    const downloadFileString = t('VIEW_FILE')
    const ideaType = ideaItem.type
    let editedIdeaType;


    switch (ideaType) {
      case 'innovacion':
        editedIdeaType = "Innovation";
        break;
      case 'improvement':
      case 'mejoras':
        editedIdeaType = "Continuous Improvement";
        break;
      case 'problema':
      case 'Problem Solving':
        editedIdeaType = "Problem Solving";
        break;
      default:
        editedIdeaType = "Problem Solving";
        break;
    }

    const tcInfo = ''
    const newCommittee = ''

    const teamMembers = ideaItem.teamMembers
    const proponent = ideaItem.proponent
    const similarOpportunity = '12345'//deaItem.get("randomIdea")
    const isDuplicateCheck = ideaItem.is.merged;

    return (
      this.props.canSubmit ?
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30em' }}>
          <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
        </div>
        :
        <div className="edit-user-details" >
          <Form className="py-4"
            onSubmit={this.onSubmit}
            noValidate
          >

            {/* VISIBILITY */}
            <div style={{ display: formVisibilityState }}>
              <Row form>
                {/* Left Part */}
                <Col lg="5">
                  <Row form>
                    {/* Proponent */}
                    <Col md="12" className="form-group">
                      <Row className="mt-4">
                        <Col>
                          <label htmlFor="firstName" className="georgia">{t("title")}</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{ideaItem.title}</h6>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-start">
                          {isDuplicateCheck && (
                            <span className="badge badge-pill badge-warning custom-badge">Merged</span>
                          )}
                        </Col>
                      </Row>


                      <Row className="mt-4">
                        <Col md="6">
                          <label htmlFor="firstName" className="georgia">{t("category")}</label>
                          <Row>
                            <Col>
                              {this.getIcon(myIcon, 'Black')}
                              <div className="mr-auto" style={{ width: '100%', backgrounColor: 'black' }}>
                                <h6 style={{ fontWeight: 500, color: '#303030' }}>{categoryTitle}</h6>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row className="mt-2">
                            {isDuplicateCheck ?
                              <Col>

                                <label htmlFor="firstName" className="georgia">Merge Date</label>
                                <h6 style={{ fontWeight: 500, color: '#303030' }}>{this.getDate(ideaItem.mergeDate)}</h6>
                              </Col>
                              :
                              <Col>
                                <label htmlFor="firstName" className="georgia">Submit Date</label>
                                <h6 style={{ fontWeight: 500, color: '#303030' }}>{parsedDate}</h6>
                              </Col>
                            }
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <label htmlFor="firstName" className="georgia">Response Status</label>
                              <Row>
                                <Col md="7">
                                  <h6 style={{ fontWeight: 500, color: '#303030' }}>{timingWording}</h6>
                                </Col>
                                <Col className="mb-auto" md="1">
                                  {timingWording == "On-Time" ?
                                    <GreenIcon style={{ height: 16, width: 16 }}></GreenIcon>
                                    :
                                    <RedIcon style={{ height: 16, width: 16 }}></RedIcon>
                                  }
                                </Col>
                                <Col md="1" className="mb-auto">
                                  <a id={"TooltipResponseInfo"} className="text-right" style={{ color: 'inherit' }} onClick={() => {
                                    const myCopy = this.state.responseInfo
                                    myCopy = !myCopy
                                    this.setState({ responseInfo: myCopy })
                                  }}>

                                    <InfoIcon style={{ height: 16, width: 16 }}></InfoIcon>

                                  </a>
                                </Col>

                                <Tooltip
                                  open={this.state.responseInfo}
                                  target={"#TooltipResponseInfo"}
                                  id={"TooltipResponseInfo1"}
                                  toggle={() => { this.toggle() }}
                                >
                                  {t("RESPONSE_TIME_MSG")}
                                </Tooltip>
                              </Row>

                            </Col>
                          </Row>

                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col>
                          <label htmlFor="firstName" className="georgia">Type</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{editedIdeaType}</h6>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col>
                          <label htmlFor="firstName" className="georgia">Department to benefit</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{ideaItem.department}</h6>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col>
                          <label htmlFor="firstName" className="georgia">Description</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{ideaItem.submittal.description}</h6>
                        </Col>
                      </Row>


                      {this.state.ideaProponentHistory.length > 0 &&
                        <Row className="mt-4">
                          <Col>
                            <label htmlFor="firstName" className="georgia">Proponent Activity</label>
                            {this.state.ideaProponentHistory.map((historyItem, index) => (
                              <div key={index} className="history-item">
                                <h6 style={{ fontSize: '1rem', fontWeight: 500, margin: 0, color: '#303030' }}>
                                  Proponent user deleted from the system on {historyItem.get("createdAt") ? moment(historyItem.get("createdAt")).format('DD MMMM YYYY') : "Date not available"}:
                                </h6>
                                <p style={{ fontSize: '.9rem', margin: 0, color: '#303030' }}>
                                  Changed from {historyItem.get("oldProponentName")} {historyItem.get("oldProponentIsSupervisor") && ' (Supervisor)'} to {historyItem.get("newProponentName")}
                                </p>
                                <p style={{ fontSize: '.9rem', margin: 0, color: '#303030' }}>
                                  System Update by: {historyItem.get("actionByName")}
                                </p>
                              </div>
                            ))}
                          </Col>
                        </Row>
                      }


                    {isDuplicateCheck && (
                        <div className="mt-4">
                          <Row>
                            <Col>
                              <label htmlFor="firstName" className="georgia">View Original Opportunities</label>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col className="d-flex">
                            <Button className="btn btn-primary custom-button mr-2" onClick={() => this.goToOpportunity(duplicateOriginalOpportunity.id)}>
                              {duplicateOriginalOpportunity ? `Original Opportunity`: 'Loading...'}
                            </Button>
                            <Button className="btn btn-primary custom-button"  onClick={() => this.goToOpportunity(duplicateSimilarOpportunity.id)}>
                              {duplicateSimilarOpportunity ? `Similar Opportunity`: 'Loading...'}
                            </Button>
                            </Col>
                          </Row>
                        </div>
                      )}

                      {/* {!isDuplicateCheck && ideaItem.file &&    } */}
                      {ideaItem.submittal && ideaItem.submittal.attachment && ideaItem.submittal.attachment.url && (
                        <Row className="mt-4">
                          <Col>
                            <label htmlFor="firstName" className="georgia">Attachments</label>
                            <Button onClick={() => this.setState({ attachmentModalOpen: true })} style={{ display: 'flex' }} >{downloadFileString}</Button>
                          </Col>
                        </Row>
                      )}

                      {!isDuplicateCheck && ideaItem.file &&
                        <Row className="mt-4">
                          <Col>
                            <label htmlFor="firstName" className="georgia">Attachments</label>
                            {this.state.attachments.map((attachment, index) => (
                              <Row key={index} className="mb-2 d-flex align-items-center">
                                <Col xs="auto" className="d-flex align-items-center pt-3">
                                  <span><h6 style={{ fontWeight: 500, color: '#303030' }}>• {attachment.name}</h6></span>
                                </Col>
                                <Col xs="auto" className="d-flex align-items-center">
                                  <Button
                                    style={{ display: 'flex' }}
                                    className="btn btn-primary custom-button"
                                    onClick={() => this.setState({ selectedOpportunity: attachment.opportunity, attachmentModalOpen: true })}
                                  >
                                    View File
                                  </Button>
                                </Col>
                              </Row>
                            ))}

                          </Col>
                        </Row>
                      }
                    </Col>

                  </Row>
                </Col>
                {/* Divisor Line */}
                <Col lg="1" className="mx-auto">
                  {/* <div style={{height: 300, width: 10, color: 'blue'}}></div> */}
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>

                {/* Right Part */}
                {ideaStage === 0 &&
                  <Col lg="6">
                    {ideaItem.submittal.evaluationHistory.map((item, index) => {
                      if (item.action === 'Transfer Committee')
                        return (
                          <div>
                            <Row className="mt-4">
                              <Col md="12" className="form-group">
                                <div style={{ backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid' }}>
                                  <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>Transfer Committee Information: </h6>
                                  <p className="mx-2"><strong>Reason for transfer: </strong>{item.reason}</p>
                                  {item.actionAt && <p className="mx-2"><strong>Transferred by: </strong>{item.actionBy} on {moment(item.actionAt).format("DD MMMM YYYY, hh:mm A")} </p>}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )

                      else if (item.action === 'Project Opportunity')
                        return (<div>
                          <Row className="mt-4">
                            <Col md="12" className="form-group">
                              <div style={{ backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid' }}>
                                <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>Project Opportunity Comments: </h6>
                                <p className="mx-2"><strong>Reason for transfer: </strong>{item.reason}</p>
                                <p className="mx-2"><strong>Is this idea aligned with our business' needs, goals and/or KPI's? </strong>{item.isAlignedWithBusinessGoals == true ? "Yes" : 'No'}</p>
                                {item.actionAt && <p className="mx-2"><strong>Evaluated by: </strong>{item.userFullName} on {moment(item.actionAt).format("DD MMMM YYYY, hh:mm A")} </p>}
                              </div>
                            </Col>
                          </Row>
                        </div>)


                      else if (item.action === 'Save for Later')
                        return (<div>
                          <Row className="mt-4">
                            <Col md="12" className="form-group">
                              <div style={{ backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid' }}>
                                <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>Save for Later Information: </h6>
                                <p className="mx-2"><strong>Reason: </strong>{item.reason}</p>
                                <p className="mx-2"><strong>Are you likely to approve this idea in the future? </strong>{item.likelyToApproveInFuture === true ? "Yes" : "Maybe"}</p>
                                {item.actionAt && <p className="mx-2"><strong>Evaluated by: </strong>{item.actionBy} on {moment(item.actionAt).format("DD MMMM YYYY, hh:mm A")} </p>}
                              </div>
                            </Col>
                          </Row>
                        </div>)


                      else if (item.action === 'RFI Submitted') {
                        return (<div>
                          <Row className="mt-4">
                            <Col md="12" className="form-group">
                              <div style={{ backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid' }}>
                                <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>RFI Submitted: </h6>
                                <p className="mx-2">{item.question}</p>
                                {item.actionAt && <p className="mx-2"><strong>Submitted by: </strong>{item.actionBy} on {moment(item.actionAt).format("DD MMMM YYYY, hh:mm A")} </p>}

                                <hr />
                                <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>Response: </h6>
                                <p className="mx-2">{item.answer}</p>
                              </div>
                            </Col>
                          </Row>
                        </div>)
                      }
                      return <></>
                    })
                    }
                    <Row form>
                      {ideaItem.submittal && ideaItem.submittal.questions.map((question, index) => {
                        if (!question) return null;
                        const prefix = `Q${numberQuestion}: `
                        const myQuestion = prefix + question["question"]
                        numberQuestion = numberQuestion + 1

                        return (
                          <Col md="12" className="form-group">
                            <Row className="mt-4">
                              <Col md="12">
                                <label className="georgia">{myQuestion}</label>
                                {question["answer"] === 'No response provided.' ? <h6 style={{ color: '#adadad' }}>{question["answer"]}</h6> : <h6 style={{ fontWeight: 500, color: '#303030' }}>{question["answer"]}</h6>}

                              </Col>
                            </Row>

                          </Col>)


                      })}
                    </Row>
                  </Col>
                }


                {/* {ideaStage == 1 &&  */}

                <Col lg="6" style={{ display: ideaStage == 1 ? 'block' : 'none' }}>
                  <div>
                    <Row form className="mt-4">
                      <Col md="12" className="form-group">
                        <h6 style={{ fontWeight: 500, color: '#303030' }}>{t('Choose_how_to_proceed') + '*'}</h6>
                        <IdeaStatusSelect setEvalStatus={this.setEvalStatus} selectedStatus={this.state.selectedStatus}></IdeaStatusSelect>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                    <Col className="form-group d-flex flex-start">
                        <Button
                          pill
                          style={{ backgroundColor: "#fff", borderColor: "#6e4db8", color: "#6e4db8", borderWidth: '2px' }}
                          onClick={() => {
                            const htmlContainer = (
                              <div id="container-mark-duplicate" style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
                                <p style={{ marginLeft: 40, marginRight: 40 }}>
                                  Mark this opportunity as a duplicate if you believe it has already been submitted or exists in the system.
                                </p>
                              </div>
                            );
                            Swal.fire({
                              title: `Do you want to mark this opportunity as a duplicate?`,
                              html: ReactDOMServer.renderToString(htmlContainer),
                              showCancelButton: true,
                              width: '800px',
                              confirmButtonText: 'Not Now',
                              cancelButtonText: 'Yes',
                              customClass: {
                                confirmButton: 'swal-confirm-btn',
                                cancelButton: 'swal-cancel-btn',
                              },
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                // Do nothing for "Not Now"
                              } else if (result.dismiss === Swal.DismissReason.cancel) {
                                try {
                                  const response = await markDuplicate(ideaItem.id);
                                  const toUrl = `/evaluate`;
                                  window.location = toUrl;
                                } catch (error) {
                                  console.error('Error marking as duplicate:', error);
                                }
                              }
                            });
                          }}
                        >
                          {t('Mark as Duplicate')}
                        </Button>
                        <Button
                          pill
                          style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8", color: "#fff", marginLeft: 10}}
                          onClick={() => this.showEvalCriteriaModal()}
                        >
                          {t('Review Evaluation Criteria')}
                        </Button>
                        </Col>
                    </Row>

                    {this.state.showEvalFields &&
                      <div>
                        <Row form className="mt-4">
                          <Col md="12" className="form-group">
                            <Switch
                              isOn={this.state.needsEconomicImpact}
                              handleToggle={() => {
                                if (this.state.needsEconomicImpact === false) {
                                  if (this.state.selectedStatus && this.state.expectedReturn && this.state.timeUnit) {
                                    this.props.changeStatus(true);
                                  } else {
                                    this.props.changeStatus(false);
                                  }
                                } else {
                                  // If needsEconomicImpact is already true, check for selectedStatus before deciding to keep changeStatus as true
                                  if (this.state.selectedStatus) {
                                    this.props.changeStatus(true);
                                  } else {
                                    this.props.changeStatus(false);
                                  }
                                }

                                this.setState(prevState => ({ needsEconomicImpact: !prevState.needsEconomicImpact }));
                              }}
                              onColor="#633FDA"
                              myKey={'economicImpact'}
                              title={t("Estimate economic/output impact")}
                            />

                            {/* <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Estimate economic/output impact *'}</h6> */}
                            {this.state.needsEconomicImpact &&
                              <Row>
                                <Col>
                                  <FormInput
                                    id="expectedReturn"
                                    placeholder={'$15,000'}
                                    value={expectedReturn}
                                    onChange={this.setExpectedReturn}
                                    className="insideFont"
                                  />
                                </Col>
                                <Col>
                                  <Select
                                    value={this.state.timeUnitVal}
                                    className="insideFont"
                                    placeholder='term'
                                    styles={customStyles}
                                    onChange={this.setTimeUnit}
                                    options={[
                                      {
                                        value: 'month',
                                        label: 'month'
                                      },
                                      {
                                        value: 'year',
                                        label: 'year'
                                      }
                                    ]}
                                  />
                                </Col>
                                <Col>
                                  <Switch
                                    isOn={this.state.recurringImpact}
                                    myKey={'recurringImpact'}
                                    handleToggle={() => this.setState({ recurringImpact: !this.state.recurringImpact })}
                                    onColor="#633FDA"
                                    title="Recurring Impact"
                                  />
                                </Col>
                              </Row>
                            }
                          </Col>
                        </Row>
                        <Row form >
                          <Col md="12" className="form-group">
                            <h6 style={{ fontWeight: 500, color: '#303030' }}>{t('COMMENTS')}</h6>
                            <FormTextarea
                              style={{ minHeight: "80px" }}
                              id="ideaQuestion"
                              className="insideFont"
                              placeholder={t('Type response here')}
                              onChange={(event) => this.commentChangeField(event)}
                              value={this.state.comment}
                              required>
                            </FormTextarea>
                          </Col>
                        </Row>

                        <Row>

                          <Col md="12" className="form-group">
                            <div>
                              <h6 style={{ fontWeight: 500, color: '#303030', display: 'inline-block' }}>{t("Add an attachment")}</h6>

                              <span>
                                <CustomFileWithProgressUpload
                                  maximumSizeFile={10}
                                  onProgressUpdate={(progress) => {
                                    this.setState({ uploadProgress: progress });
                                    this.props.changeStatus(progress >= 100);
                                  }}
                                  onFileUpload={(file) => {
                                    this.setState({ file });
                                  }}
                                />
                              </span>
                            </div>


                          </Col>
                        </Row>
                      </div>
                    }

                    {this.state.showRfiField &&
                      <Row className="mt-4">
                        <Col md="12" className="form-group">
                          <div style={{ backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid' }} onClick={() => this.editRfi()}>
                            <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>RFI Submitted: </h6>
                            <p className="mx-2">{this.state.rfiInfo}</p>
                          </div>
                        </Col>
                      </Row>
                    }

                    {(this.state.showTcField) &&
                      <Row className="mt-4">
                        <Col md="12" className="form-group">
                          <div style={{ backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid' }} onClick={() => this.editTc()}>
                            <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>Transferred to New Committee: {newCommittee}</h6>
                            <p className="mx-2"><strong>Reason for Transfer: </strong>{this.state.tcInfo || tcInfo}</p>
                          </div>
                        </Col>
                      </Row>
                    }

                    {this.state.showPmoField &&
                      <Row className="mt-4">
                        <Col md="12" className="form-group">
                          <div style={{ backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid' }} onClick={() => this.editPmo()}>
                            <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>Transferred to Project Evaluation Team: </h6>
                            <p className="mx-2"><strong>Date: </strong>{moment(new Date()).format("DD MMMM YYYY")}</p>
                            <p className="mx-2"><strong>Reason for transfer: </strong>{this.state.pmoInfo}</p>
                            <p className="mx-2"><strong>Is this idea aligned with our business' needs, goals and/or KPI's? </strong>{this.state.pmoSelection ? 'Yes' : 'No'}</p>
                          </div>
                        </Col>
                      </Row>
                    }

                    {this.state.showSflField &&
                      <Row className="mt-4">
                        <Col md="12" className="form-group">
                          <div style={{ backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid' }} onClick={() => this.editSfl()}>
                            <h6 className="ml-2 mt-2" style={{ fontWeight: 500, color: '#303030' }}>Saved for Later: </h6>
                            <p className="mx-2"><strong>Date: </strong>{moment(new Date()).format("DD MMMM YYYY")}</p>
                            <p className="mx-2"><strong>Reason: </strong>{this.state.sflInfo}</p>
                            <p className="mx-2"><strong>Are you likely to approve this idea in the future? </strong>{this.state.sflSelection === true ? 'Yes' : this.state.sflSelection === false ? 'No' : 'Maybe'}</p>
                          </div>
                        </Col>
                      </Row>
                    }

                  </div>
                </Col>

                {/* } */}



                {ideaStage == 2 &&
                  <Col lg="6">
                    <Row className="mt-4">
                      <Col md="6">
                        <label htmlFor="firstName" className="georgia">{t('Evaluation Desicion')} </label>
                        <Row>
                          <Col>
                            {this.getIcon(this.state.selectedStatus, 'Black')}
                            <div className="mr-auto" style={{ width: '100%', backgrounColor: 'black' }}>
                              <h6 style={{ fontWeight: 500, color: '#303030' }}>{this.state.selectedStatus}</h6>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row className="mt-2">
                          <Col>
                            <label htmlFor="firstName" className="georgia">Evaluation Date</label>
                            <h6 style={{ fontWeight: 500, color: '#303030' }}>{nowDate}</h6>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <label htmlFor="firstName" className="georgia">Status</label>
                            <Row>
                              <Col md="7">
                                <h6 style={{ fontWeight: 500, color: '#303030' }}>{'Pending'}</h6>
                              </Col>
                              <Col className="mb-auto" md="1">
                                {/* <div className="my-auto" style={{backgroundColor: '#1DE334', height: 16, width: 16, borderRadius: 8}}></div> */}
                                {/* { timingWording == "On-Time"? 
                                            <GreenIcon style={{height: 16, width: 16}}></GreenIcon>
                                            : */}
                                <RedIcon style={{ height: 16, width: 16 }}></RedIcon>
                                {/* } */}
                              </Col>

                              <Col md="1" className="mb-auto">
                                <a id={"TooltipResponseInfo2"} className="text-right" style={{ color: 'inherit' }} onClick={() => {
                                  const myCopy = this.state.responseInfo2
                                  myCopy = !myCopy
                                  this.setState({ responseInfo2: myCopy })
                                }}>

                                  <InfoIcon style={{ height: 16, width: 16 }}></InfoIcon>

                                </a>
                              </Col>

                              <Tooltip
                                open={this.state.responseInfo2}
                                target={"#TooltipResponseInfo2"}
                                id={"TooltipResponseInfo2"}
                                toggle={() => { this.toggleTwo() }}
                              >
                                {this.getIconDescription(this.state.selectedStatus)}

                              </Tooltip>
                            </Row>

                          </Col>
                        </Row>

                      </Col>
                    </Row>

                    {/* Subject Matter Comments */}
                    <Row form className="mt-4">
                      <Col md="12" className="form-group">
                        <label htmlFor="firstName" className="georgia">{t("Thisopportunitywassubmittedby")}</label>
                        <h6 style={{ fontWeight: 500, color: '#303030' }}>{proponent}</h6>
                      </Col>
                    </Row>

                    {teamMembers.length > 0 &&
                      <Row form className="mt-4">
                        <Col md="12" className="form-group">
                          <label htmlFor="firstName" className="georgia">{t("TEAM_MEMBERS")}</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{teamMembers.join(" • ")}</h6>
                        </Col>
                      </Row>
                    }


                    {this.state.comment &&
                      <Row form className="mt-4">
                        <Col md="12" className="form-group">
                          <label htmlFor="firstName" className="georgia">{t("Subject-MatterExpertComments")}</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{this.state.comment}</h6>
                        </Col>
                      </Row>
                    }

                    {this.state.needsEconomicImpact &&
                      <Row form className="mt-4">
                        <Col md="12" className="form-group">
                          <label className="georgia">{'Estimate economic/output impact'}</label>
                          <Row>
                            <Col>
                              <h6 style={{ fontWeight: 500, color: '#303030' }}>{'$' + this.state.expectedReturn}</h6>
                            </Col>
                            <Col md="4">
                              <h6 style={{ fontWeight: 500, color: '#303030' }}>{this.state.timeUnit}</h6>
                            </Col>
                            <Col md="4">
                              <Switch
                                isOn={this.state.recurringImpact}
                                disabled
                                // handleToggle={() => this.setState({recurringImpact: !this.state.recurringImpact})}
                                onColor="#633FDA"
                                title="Recurring Impact"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    }

                    {this.state.selectedStatus !== "Do not Pursue" && this.state.selectedStatus !== "Save for Later" &&
                      <Row form className="mt-4">
                        <Col md="12" className="form-group">
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{'Choose an Implementation Leader *'}</h6>
                          <ExecutionSelectNew className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeResponsible(res, idx)} selectedVal={executionRes} />
                          <br />
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{'Choose a Coach'}</h6>
                          <ExecutionSelectNew className="insideFont" evalType={'coach'} setResponsible={(res, idx) => this.changeCoach(res, idx)} selectedVal={coachRes} />
                        </Col>
                      </Row>
                    }
                  </Col>
                }
              </Row>




            </div>
          </Form>
          {ideaStage < 1 && isDuplicate &&
            <Row className="mt-4 floating-buttons pb-4">
              {/* Add a column that takes full width and includes a title */}
              <Col xs={12}>
                <div className="text-center mt-2 pb-2">
                  <h6 style={{ fontWeight: 500, color: '#303030' }}>Select an option</h6>
                </div>
              </Col>
              <Col className="button-col">
                <div className="text-center mb-2">
                  <Button pill style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8" }} onClick={() => this.setState({ mergeIdeasOpen: true })}>Merge</Button>
                </div>
              </Col>
              <Col className="button-col">
                <div className="text-center mb-2">
                  <Button pill style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8" }} onClick={() => this.changeIdeaStageBack()}>Review Later</Button>
                </div>
              </Col>
              <Col className="button-col">
                <div className="text-center mb-2">
                  <Button pill style={{ backgroundColor: "#6e4db8", borderColor: "#6e4db8" }} onClick={() => this.changeIdeaStage()}>Not Duplicate</Button>
                </div>
              </Col>
            </Row>
          }
          {ideaItem.submittal && ideaItem.submittal.attachment && (
            <IdeaAttachmentModal ideaItem={ideaItem.submittal.attachment} onClick={(visible) => { this.setState({ attachmentModalOpen: visible }) }} isVisible={this.state.attachmentModalOpen} type="file" />
          )}



          <Modal open={this.state.showEvalCriteria} centered backdrop toggle={() => this.setState({ showEvalCriteria: false })}>
            <ModalFooter>
              <h5 style={{ fontWeight: 500, color: '#303030', marginRight: '10px' }}>Evaluation Criteria</h5>
              <CancelIcon className="functionalButton ml-auto" style={{ height: 34, width: 34 }} onClick={() => this.setState({ showEvalCriteria: false })}></CancelIcon>
            </ModalFooter>
            <div style={{ width: '100%', height: 1, backgroundColor: 'black', opacity: 0.1 }}></div>
            <ModalBody style={{ maxHeight: '300px', overflowY: 'scroll' }}>
              <Row>
                <Col>
                  <div dangerouslySetInnerHTML={{ __html: this.state.evaluationCriteria }}></div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <MoreInformationModal isOpen={this.state.rfiOpen} closeModal={() => this.closeModal()} changeText={(text) => this.changeRfiInfo(text)} text={this.state.rfiInfo} type="requestInformation" idea=''></MoreInformationModal>
          <TransferCommitteeModal isOpen={this.state.transferCommitteeOpen} closeModal={() => this.handleCloseTC()} changeText={(text) => this.changeTcInfo(text)} changeCommittee={(newComm) => this.changeCommInfo(newComm)} text={this.state.tcInfo} idea=''></TransferCommitteeModal>
          <SaveForLaterModal isOpen={this.state.sflOpen} closeModal={() => this.handleCloseSFL()} changeText={(text) => this.changeSflInfo(text)} changeSelection={(selection) => this.changeSflSelection(selection)} text={this.state.sflInfo} idea=''></SaveForLaterModal>
          <PMOModal isOpen={this.state.pmoOpen} closeModal={() => this.handleClosePMO()} changeText={(text) => this.changePmoInfo(text)} changeSelection={(selection) => this.changeSelection(selection)} text={this.state.pmoInfo} idea=''></PMOModal>
          {isDuplicate && <MergeIdeasModal isOpen={this.state.mergeIdeasOpen} closeModal={() => this.setState({ mergeIdeasOpen: false })} changeText={(text) => this.changePmoInfo(text)} changeSelection={(selection) => this.changeSelection(selection)} text={this.state.pmoInfo} idea={ideaItem} similarOpportunity={similarOpportunity}></MergeIdeasModal>}
        </div>

    );
  }
}




export default withTranslation()(withRouter(IdeaViewCardNew));