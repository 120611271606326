import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "shards-react";

import { saveAs } from 'file-saver';
import ImportDataModal from '../components/administration/ImportDataModal';
import NewUserItem from '../components/administration/NewUserItem';
import PageTitle from "../components/common/PageTitle";
import SettingsActionButtons from '../components/common/SettingsActionButtons';
import UserListNew from '../components/ecommerce/UserListNew';
import { ReactComponent as AddUserIcon } from "../images/add_user.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import colors from "../utils/colors";

import UserTemplate from '../assets/Murmuratto_Template_User.csv';
import administrationService from '../services/administrationService';

function AdministrationUsersNew(smallStats) {
  const [title, setTitle] = useState('');
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [reset, setReset] = useState(false);
  const [shouldUpdateUsers, setShouldUpdateUsers] = useState(false);
  const [user, setUser] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [dataCsv, setDataCsv] = useState([]);
  const [users, setUsers] = useState([]);
  
  const setInitialTitle = async (user) => {
    setTitle('Users');
  };

  useEffect(() => {
    setInitialTitle();
    fetchUsers();
  }, []);

  useEffect(() => {
    if(shouldUpdateUsers) fetchUsers();
  }, [shouldUpdateUsers]);

  const fetchUsers = async () => {
    try {
      const response = await administrationService.getUsers(); // Call your service to fetch users
      setUsers(response);
      setShouldUpdateUsers(!shouldUpdateUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  const updateCategories = () => {
    setShouldUpdateUsers(!shouldUpdateUsers);
    setShowUser(false);
    setUser('');
  };

  const createUser = () => {
    setUser('');
    setReset(Math.random());
    setShowUser(true);
  };

  const editItem = (item) => {
    setUser(item);
    setReset(Math.random());
    setShowUser(true);
  };

  const uploadData = () => {
    setOpenModal(true);
  };

  const onFinish = () => {
    alert("Your data was imported successfully!");
    setOpenModal(false);
    setShouldUpdateUsers(!shouldUpdateUsers);
    setShowUser(false);
    setUser('');
  };

  const exportData = async () => {
    setLoadingDownload(true);

    try {
      const csvData = await administrationService.exportUsers();

      if (!csvData || csvData.length === 0) {
        setLoadingDownload(false);
        return;
      }

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `users_${currentDate}.csv`;

      saveAs(blob, fileName);

    } catch (error) {
      console.error('Error exporting users:', error);
    } finally {
      setLoadingDownload(false);
    }
  }

  return (
    <Container fluid className="main-content-container px-4" style={{backgroundColor: 'white'}}>
      <Row>
        <Col md="12" lg="12" className="pt-4 mx-auto">
          <Row className="align-items-center d-flex">
            <Col md="8" lg="8" className="d-flex align-items-center">
              <Row noGutters className="page-header">
                    <PageTitle title={title} subtitle={'Administration'} className="text-sm-left mb-3" />
              </Row>
            </Col>
            
            <Col xs="12" md="4" lg="4" className="d-flex justify-content-end align-items-center">
              <SettingsActionButtons uploadData={() => uploadData()} templateFile={UserTemplate} dataCsv={dataCsv} exportData={() => exportData()}></SettingsActionButtons>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivisorBarIcon></DivisorBarIcon>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row className="mt-4">
        <Col lg="12" className="m-auto">
          <AddUserIcon className="functionalButton mr-4 d-block mb-4" style={{minWidth: 180, maxWidth:180}} onClick={() => createUser()}></AddUserIcon>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md="12" lg="12">
          {showUser && <NewUserItem  key={Math.random()} id={Math.random()} open={showUser} shouldReset={reset} updateUsers={updateCategories} user={user}></NewUserItem>}
        </Col>
      </Row>

      <Row className="mt-2">
        <Col lg="12" className="m-auto">
          <div style={{backgroundColor: '#F6F6F6', maxHeight: 500, overflow: 'auto', borderStyle: 'solid', borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 100}}>
            <UserListNew shouldUpdate={shouldUpdateUsers} users={users} editItem={(user) => editItem(user)} setDataCsv={(data) => setDataCsv(data)}/>
          </div>
        </Col>
      </Row>
      
      <ImportDataModal open={openModal} onFinish={() => onFinish()} dataType="users"></ImportDataModal>
    </Container>
  );
}

AdministrationUsersNew.propTypes = {
  smallStats: PropTypes.array
};

AdministrationUsersNew.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default AdministrationUsersNew;
