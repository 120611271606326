import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert, Col, Row } from "shards-react";
import NextSteps from '../components/administration/NextSteps';
import IdeaPercentageBar from '../components/common/IdeaPercentageBar';
import IdeaViewCardNew from '../components/common/IdeaViewCardNew';
import ImplementDuplicate from "../components/common/ImplementDuplicate";
import PageTitle from "../components/common/PageTitle";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import { authUser } from '../services/userService';
import colors from "../utils/colors";
import IdeaInfoCompleted from './IdeaInfoCompleted';


function ImplementIdea({ closeModal, currIdea, updateCurrIdea, updateActivityHistory, showA3, updatePercentage, openImplementedForm, openDuplicateForm, openNotImplementedForm, showModal = true }) {
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(0)
  const [showNext, setShowNext] = useState(true)
  const [currentPage, setCurrentPage] = useState('idea')
  const [showIdeaInformation, setShowIdeaInformation] = useState(false)
  const [showA3Btn, setShowA3Btn] = useState(currIdea.progress == 100)
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [incompleteTasks, setIncompleteTasks] = useState(undefined)
  const [markTasks, setMarkTasks] = useState(undefined)
  const [canEdit, setCanEdit] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const currentUser = authUser();

  // this.getUserName()  

  const getUserName = async (user) => {

    
    setUsername(currentUser.firstName + ' ' + currentUser.lastName)

    // Update welcome title
    if (ideaStage == 0) {

      // We want to use t(VARIABLE) so that we can get the correct string
      // Replace 'VARIABLE' with t('VARIABLE_NAME')
      // VARIABLE_NAME being the corresponding variable in our new json file.

      // Uncomment the following line to create a message string
      // const welcomeMsg = 'VARIABLE' + username + '!'


      // Replace the contents of setTitle with our new welcomeMsg variable.
      setTitle('Keep your team updated!') // Here we are setting our title to what we want
    }
  }

  useEffect(() => {
    getUserName(currentUser)
  });

  const setFinished = () => {
    setIdeaStage(3)
  }

  useEffect(() => {
    if(!currIdea || !username) return

    if(currIdea.coaches.includes(username) || currIdea.implementationLeader === username){
      setCanEdit(true)
    }
    
    if(!currIdea.coaches.includes(username) && currIdea.implementationLeader !== username){
      setShowAlert(true)
    }else{
      setShowAlert(false)
    }
  }, [currIdea, username])
  const AlertIcon = (color) => (<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.0156 11.6158L10.9969 1.93145C10.5188 1.28457 9.78752 0.918945 9.00002 0.918945C8.18439 0.918945 7.45314 1.28457 7.00314 1.93145L0.984395 11.6158C0.421895 12.3752 0.33752 13.3596 0.759395 14.2033C1.18127 15.0471 2.02502 15.5814 2.98127 15.5814H15.0188C15.975 15.5814 16.8188 15.0471 17.2406 14.2033C17.6625 13.3877 17.5781 12.3752 17.0156 11.6158ZM16.1156 13.6408C15.8906 14.0627 15.4969 14.3158 15.0188 14.3158H2.98127C2.50315 14.3158 2.10939 14.0627 1.88439 13.6408C1.68752 13.2189 1.71564 12.7408 1.99689 12.3752L8.01564 2.69082C8.24064 2.38145 8.60627 2.18457 9.00002 2.18457C9.39377 2.18457 9.7594 2.35332 9.9844 2.69082L16.0031 12.3752C16.2844 12.7408 16.3125 13.2189 16.1156 13.6408Z" fill="white"/>
<path d="M8.9999 6.15039C8.6624 6.15039 8.35303 6.43164 8.35303 6.79727V9.86289C8.35303 10.2004 8.63428 10.5098 8.9999 10.5098C9.36553 10.5098 9.64678 10.2285 9.64678 9.86289V6.76914C9.64678 6.43164 9.3374 6.15039 8.9999 6.15039Z" fill="white"/>
<path d="M8.9999 11.25C8.6624 11.25 8.35303 11.5312 8.35303 11.8969V12.0375C8.35303 12.375 8.63428 12.6844 8.9999 12.6844C9.36553 12.6844 9.64678 12.4031 9.64678 12.0375V11.8688C9.64678 11.5313 9.3374 11.25 8.9999 11.25Z" fill="white"/>
</svg>

  );
  return (
    <div  style={{ backgroundColor: 'white', position: "relative", paddingBottom: (!currIdea.implement && currIdea.progress === 100) ?  "200px" : "0" }}>
      <Row>
        {/* Page Header :: Title */}
        <Col md="12" lg="12" className="mx-auto">
          <Row>
            <Col md="12" lg="12">
            <Row noGutters className="page-header pt-4 pb-2">
              <PageTitle title={title} subtitle="Implement Workflow" className="text-sm-left mb-3" />
          </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivisorBarIcon></DivisorBarIcon>
            </Col>
          </Row>
        </Col>
        {/* Page Header :: Actions */}

      </Row>

      {/* Divider */}
      {showAlert && <Alert style={{
        backgroundColor: '#FFFBEB', 
        color: '#9D5425', 
        borderRadius: 9, 
        borderLeft: '6px solid #FBBF24', 
        fontWeight: 300,
        display: 'flex',
        alignItems: 'center'
      }}>
        <div style={{
          display: 'inline-flex',
          backgroundColor: '#FBBF24', 
          lineHeight: '0px',
          borderRadius: 6, 
          width: 28, 
          height: 28, 
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '8px'
        }}>
           <AlertIcon color="white" />
        </div>
        
        <div style={{marginLeft: '8px'}}>
          <strong style={{fontWeight: 800}}>Alert:</strong> Only opportunity owners or coaches can modify opportunities (Add tasks, Change dates, etc.)
        </div>
      </Alert>}

      {showIdeaInformation && <Row className="mt-4">
        <Col md="12" lg="12">
          <Row>
            <Col md="12" className="m-auto">
              <IdeaViewCardNew canSubmit={false} setFinishedSaving={() => setFinished()} ideaItem={currIdea} ideaStage={0} />
            </Col>
          </Row>
        </Col>
      </Row>}

      {!showIdeaInformation &&
        <div>
          {currentPage === 'idea' &&
            <Row className="mt-4">
              <Col md="12" lg="12">
                <IdeaPercentageBar disabled={!canEdit} updatePercentage={(percentage) => updatePercentage(percentage)} canClick={!showA3Btn} reloadData={() => setShouldReloadData(true)} currIdea={currIdea} updateIdea={() => updateCurrIdea()} showA3={(canShow) => showA3(canShow)} incompleteTasks={incompleteTasks} markTasksRed={setMarkTasks}></IdeaPercentageBar>
              </Col>
            </Row>
          }

          {(!currIdea.implement) && showNext &&
            <Row className="mt-4">
              <Col md="12" lg="12">
                <NextSteps disabled={!canEdit} changeShouldReload={(status) => setShouldReloadData(status)} currIdea={currIdea} open={true} shouldReset={false} updateIdea={() => updateCurrIdea()} user={{}} setIncompleteTasks={(val) => setIncompleteTasks(val)} markTasksRed={markTasks}></NextSteps>
              </Col>
            </Row>
          }

          <Row className="mt-4">
            <Col md="12" lg="12">
              <IdeaInfoCompleted disabled={!canEdit} setPage={setCurrentPage} setShowInfo={(boolean) => { setShowNext(!boolean) }} currIdea={currIdea} updateCurrIdea={() => this.updateCurrIdea()} closeModal={() => showIdeaInformation ? setShowIdeaInformation(false) : closeModal()} stage="completed" closeA3={() => this.setState({ showA3: false })}></IdeaInfoCompleted>
            </Col>
          </Row>
        </div>
      }

      {
      (!currIdea.implement && currIdea.progress === 100 && canEdit) &&
        <ImplementDuplicate
          showA3={showA3}
          openDuplicateForm={openDuplicateForm}
          openImplementedForm={openImplementedForm}
          openNotImplementedForm={openNotImplementedForm}
          progress={currIdea.progress}
        />
        }
    </div>
  )
}

ImplementIdea.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

ImplementIdea.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(ImplementIdea);