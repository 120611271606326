import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "shards-react";

import DepartmentItem from '../components/administration/DepartmentItem';
import PageTitle from "../components/common/PageTitle";
import administrationService from "../services/administrationService"; // Import your service

import ReactLoading from 'react-loading';
import DepartmentTemplate from '../assets/Murmuratto_Template_Department.csv';
import { ReactComponent as AddDepartmentIcon } from "../images/add_a_department.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";

import ImportDataModal from '../components/administration/ImportDataModal';
import SettingsActionButtons from '../components/common/SettingsActionButtons';

function AdministrationDepartments(smallStats) {
  const [title, setTitle] = useState('Departments');
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState(null);
  const [idToHighlight, setIdToHighlight] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataCsv, setDataCsv] = useState([]);

  useEffect(() => {
    // Fetch departments when the component mounts
    getDepartments();
  }, []);

  // Fetch departments from the backend
  const getDepartments = async (idToHighlight = null, loading = true) => {
    setLoading(loading);
    if(newDepartment){
      if(idToHighlight) 
        setNewDepartment(null);

      setIdToHighlight(idToHighlight);
    } 
    try {
      const response = await administrationService.getDepartments();  // Use the service to fetch departments

      if(newDepartment){
        if (idToHighlight) {
          const departmentIndex = response.findIndex(dept => dept.id === idToHighlight);
          if (departmentIndex !== -1) {
            const [highlightedDepartment] = response.splice(departmentIndex, 1);
            response.unshift(highlightedDepartment); // Mueve el departamento al principio
          }
        }
      }

      setDepartments(response);
      transformDataToTemplate(response);
    } catch (error) {
      console.error('Error fetching departments:', error);
    } finally{
      setLoading(false);
    }
  };

  // Transform the fetched data to match CSV template structure
  const transformDataToTemplate = (results) => {
    const modifiedData = results.map((department) => {
      return {
        "DEPARTMENT ENG": department.itemNameTrans.en,
        "DEPARTMENT 2ND LANGUAGE": department.itemNameTrans.es,
        "DEPARTMENT LEADER": department.departmentLeader,
      };
    });
    
    setDataCsv(modifiedData);
  };

  // Handle creating a new department (this is just a placeholder for now)
  const createDepartment = () => {
    const newDepartment = {
      itemNameTrans: { en: '', es: '' },
      departmentLeader: '',
    };
    setNewDepartment(newDepartment);
  };

  // Handle file upload modal
  const uploadData = () => {
    setOpenModal(true);
  };

  
// Handle downloading the CSV template
const downloadData = async () => {
  try {
    await administrationService.exportDepartments(); // Call the export service
    
  } catch (error) {
    console.error('Error downloading the CSV file');
    alert('There was an error downloading the CSV file.');
  }
};
  // Handle finishing the import data modal
  const onFinish = () => {
    alert("Your data was imported successfully!");
    setOpenModal(false);
    getDepartments();
  };

  return (
        <Container fluid className="main-content-container px-4" style={{backgroundColor: 'white'}}>
    <Row>
        <Col md="12" lg="12" className="pt-4 mx-auto">
          <Row className="align-items-center d-flex">
            <Col md="8" lg="8" className="d-flex align-items-center">
              <Row noGutters className="page-header">
                <PageTitle title={title} subtitle={'Administration'} className="text-sm-left mb-3" />
              </Row>
            </Col>
            
            <Col xs="12" md="4" lg="4" className="d-flex justify-content-end align-items-center">
              <SettingsActionButtons uploadData={() => uploadData()} templateFile={DepartmentTemplate} dataCsv={dataCsv} exportData={() => downloadData()}></SettingsActionButtons>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivisorBarIcon></DivisorBarIcon>
            </Col>
          </Row>
        </Col>
      </Row>
    {/* <Container fluid className="main-content-container px-4 pb-4" style={{backgroundColor: 'white'}}>
      <Row>
        <Col md="10" lg="10" className="mt-4 mx-auto">
          <Row>
            <Col md="8" lg="8">
              <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
            </Col>
            <Col xs="12" md="1" lg="1" className="col d-flex align-items-center ml-auto">
              <SettingsActionButtons uploadData={() => uploadData()} templateFile={DepartmentTemplate} dataCsv={dataCsv} exportData={() => downloadData()}></SettingsActionButtons>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivisorBarIcon></DivisorBarIcon>
            </Col>
          </Row>
        </Col>
      </Row> */}

    {loading &&
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
        <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
      </div>}



      {!loading && (
        <>
          <Row className="mt-4">
            <Col lg="12" className="m-auto">
              <AddDepartmentIcon 
                className="functionalButton functionalButton mr-4 d-block mb-4" 
                style={{ minWidth: 180, maxWidth: 180, overflow: 'visible' }} 
                onClick={() => createDepartment()} 
              />
            </Col>
          </Row>
          
          {newDepartment && (
            <Row className="mt-2">
              <Col md="12" lg="12">
                <DepartmentItem 
                  key={Math.random()} 
                  id={Math.random()} 
                  category={newDepartment} 
                  isNew={true} 
                  updateCategories={getDepartments} 
                />
              </Col>
            </Row>
          )}
          
          {departments && departments.map((department, i) => (
            <Row className="mt-2" key={i}>
              <Col md="12" lg="12">
                <DepartmentItem 
                  open={department.id === idToHighlight} 
                  key={Math.random()} 
                  id={Math.random()} 
                  category={department} 
                  updateCategories={getDepartments} 
                />
              </Col>
            </Row>
          ))}
        </>
      )}



      <ImportDataModal open={openModal} onFinish={onFinish} dataType="department"></ImportDataModal>
    </Container>
  );
}

AdministrationDepartments.propTypes = {
  smallStats: PropTypes.array
};

AdministrationDepartments.defaultProps = {
  smallStats: [
    // Your default props for smallStats
  ]
};

export default AdministrationDepartments;
