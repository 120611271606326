import { saveAs } from 'file-saver';
import { toInteger } from "lodash";
import React from "react";
import { withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import {
  Button, ButtonGroup, Card,
  CardBody,
  CardFooter, Col, Container, Row, Tooltip
} from "shards-react";
import BarChart from "../components/charts/BarChart";
import PieChart from "../components/charts/PieChart";
import SankeyChart from "../components/charts/SankeyChart";
import PageTitle from "../components/common/PageTitle";
import ResponsiveTable from '../components/responsive-table/ResponsiveTable';
import reportsService from "../services/reportsService";
import { authUser } from "../services/userService";
import colors from "../utils/colors";

class EnterpriseR2 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      tooltipOpen: false,
      users: [],
      ideas: [],
      indContData: [],
      indDeptData: [],
      numSubmitted: '',
      numCompleted: '',
      numInProgress: '',
      numPendingEval: '',
      numCompletedOther: '',
      numInProgressOther: '',
      numPendingEvalOther: '',
      numSubmittedOther: '',
      departments: '',
      categories: [],
      otherCategories: [],
      ideaByDepartmentData: [],
      ideasPerSite: [],
      ideasBySiteData: [],
      progressByCategoryData: [],
      returnsByCategoryData: [],
      actualReturnsByCategoryData: [],
      completedIdeas: [],
      completeIdeas: [],
      ideasInProgress: [],
      ideasPerCats: [],
      ideaResults: [],
      showChart: false,
      showIdeasByDeptChart: false,
      showProgressByCatChart: false,
      showEarningsByCatChart: false,
      exportData: {},
      ideaDataCsv: [],
      agingDataCsv: [],
      verifications: [],
      otherVerifications: [],
      showChartActual: false,
      duplicates: [],
      otherIdeaResults: [],
      selectedOption: 'All Sites',
      showIdeasBySiteData: false,
      numVerifiedOther: '',
      numVerified: '',
      numEmployees: 0,
      numEmployeesOther: 0,
      selectedDate: 'All Time',
      selectedDateValue: 'All Time',
      evaluatedIdeas: [],
      notEvaluatedIdeas: [],
      rewardRecogIdeas: [],
      totalAmountEstimated: '',
      totalActualEarnings: '',
      totalVerified: '',
      ideasByDepartmentData: [],
      recurringPhrases: [],
      myKeyPhrases: [],
      ideasByCategoryForPie: [],
      ideasByTypeForPie: [],
      busnessImpact: [],
      ideasPerCategory: [],
      ideasPerType: [],
      earningsByCategoryData: [],
      actualEarningsByCategoryData: [],
      loadingButton: false,
      loadingType: ''
    }

    this.getIdeas = this.getIdeas.bind(this);
  }

  componentDidMount() {
    try {
      this.getIdeas();
    } catch (error) {
      console.error('Error en componentDidMount al obtener ideas:', error);
    }
  }

  handleOptionChange = (newSite) => {
    this.setState({
      selectedOption: newSite.target.value
    });
  }

  getEarningsByCategoryData(forecasted) {
    const { t } = this.props;

    const series = [
      {
        name: 'Returns',
        data: forecasted.map((type) => {
          return { x: type.category, y: type.total }
        })
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },

        yaxis: {
          title: {
            text: t('IDEA_MANAGE_CATS'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        xaxis: {
          title: {
            text: t('BUSINESS_IMPACT'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (val) {
              return val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return val.toLocaleString()
            }
          }
        }
      }
    };

    return demoData;
  }

  setupNumberOfIdeasByCategoryData(byType) {
    const { t } = this.props;

    const series = [
      {
        name: 'Ideas',
        data: byType.map((type) => {
          return { x: type.label, y: type.count }
        })
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          title: {
            text: t('IDEA_MANAGE_CATS'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        xaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

            },
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return val.toLocaleString()
            }
          }
        }
      }
    };

    return demoData;
  }

  setupNumberOfIdeasByTypeData(byType) {
    const { t } = this.props;

    const series = [
      {
        name: 'Ideas',
        data: byType.map((type) => {
          return { x: type.label, y: type.count }
        })
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },

        yaxis: {
          title: {
            text: t('OPPORTUNITY_TYPE'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        xaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

            },
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return val.toLocaleString();
            },
          },
        },
      },
    };

    return demoData;
  }

  async getIdeas() {
    try {
      const results = await reportsService.fetchReportsLocalIdeas();

      const categoryColors = ['#3e8df4', '#2961a8', '#5e41d2', '#ed7333', '#7ec3fa', '#9cd64c', '#5fb2f9', '#8577db', '#6e952f'];
      const byCategories = results.opportunities.byCategories;
      const ideasPerCategory = this.setupNumberOfIdeasByCategoryData(byCategories)
      const totalCategories = byCategories.reduce((acc, category) => acc + (category.count || 0), 0);
      const dataByCategories = byCategories.map((categoryName, index) => {
        const color = categoryColors[index] || this.getRandomColor();
        const percentage = totalCategories > 0 ? ((categoryName.count / totalCategories) * 100).toFixed(2) : 0;
        return { label: categoryName.label || 'Unknown', value: parseFloat(percentage), color };
      });


      const byTypes = results.opportunities.byTypes;
      const ideasPerType = this.setupNumberOfIdeasByTypeData(byTypes);

      const totalByTypes = byTypes.reduce((acc, type) => acc + (type.count || 0), 0);
      const dataByTypes = byTypes.map((type) => {
        let color = this.getRandomColor();

        if (type.label === 'Innovation')
          color = "#2961a7";
        if (type.label === 'Problem Solving')
          color = "#3f8cf4";
        if (type.label === 'Continuous Improvement')
          color = "#ed7333";
        const percentage = totalByTypes > 0 ? ((type.count / totalByTypes) * 100).toFixed(2) : 0;

        return {
          label: type.label,
          value: parseFloat(percentage),
          color,
        };
      });

      const earningsByCategoryData = this.getEarningsByCategoryData(results.busnessImpact.forecasted.earnings)
      const actualEarningsByCategoryData = this.getActualEarningsByCategoryData(results.busnessImpact.verified.earnings)
      
      this.setState({
        ideas: results.opportunities,
        ideasByTypeForPie: dataByCategories,
        ideasByCategoryForPie: dataByTypes,
        busnessImpact: results.busnessImpact,
        totalAmountEstimated: results.busnessImpact.forecasted.total,
        totalVerified: results.busnessImpact.verified.total,
        ideasPerCategory: ideasPerCategory,
        ideasPerType: ideasPerType,
        earningsByCategoryData: earningsByCategoryData,
        actualEarningsByCategoryData: actualEarningsByCategoryData,
        indDeptData: results.departmentsWithMostOpportunities,
        indContData: results.employeesWithMostOpportunities
      });
    } catch (error) {
      console.error('Error fetching ideas:', error);
    }
  }

  getRecurringPhrases() {
    const data = this.state.myKeyPhrases;
    const stopwords = ['we', 'this', 'a', 'an', 'the', 'it', 'is', 'to', 'of', 'and', 'in']; // Add more as needed

    const counts = data.reduce((acc, item) => {
      item.KeyPhrases.forEach(phrase => {
        const text = phrase.Text.toLowerCase();
        if (text.split(' ').length > 1 && phrase.Score > 0.95 && !stopwords.includes(text)) {
          acc[text] = (acc[text] || 0) + 1;
        }
      });
      return acc;
    }, {});

    return Object.entries(counts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([text, count]) => ({ x: text, y: count }));
  }


  setupIdeasBySiteData() {
    const { ideas, otherIdeaResults } = this.state;
    const allIdeasNum = ideas.concat(otherIdeaResults).length;
    const site1IdeasNum = ideas.length;
    const site2IdeasNum = otherIdeaResults.length;

    const results = [{
      label: 'All Sites',
      data: [allIdeasNum]
    },
    {
      label: 'SFO',
      data: [site1IdeasNum]
    },
    {
      label: 'Amgen',
      data: [site2IdeasNum]
    }, []];



    this.setState({ ideasBySiteData: results, showIdeasBySiteData: true });

  }


  setupEarningsByCategoryData() {
    const { ideas, categories } = this.state;

    let totalAmount = 0;
    const zeroData = categories.map((category) => {
      const categoryName = category.get('itemName');
      const filteredDataCount = ideas.filter((idea) => idea.get('category') === categoryName);
      let earnings = 0;

      filteredDataCount.forEach((idea) => {
        earnings += idea.get('expectedReturn');
      });

      totalAmount += earnings;

      return [categoryName, earnings];
    });

    const results = [
      {
        label: 'Returns',
        data: zeroData,
      },
      [],
    ];

    this.setState({ returnsByCategoryData: results, showChart: true, totalAmountEstimated: totalAmount });
  }


  // Setup expected return by site
  setupEarningsBySiteData(type) {
    const { ideas, otherIdeaResults } = this.state;

    const correctField = 'expectedReturn'

    var expectedEarningsSite1 = 0
    if (ideas) {
      for (var i in ideas) {
        expectedEarningsSite1 = expectedEarningsSite1 + ideas[i].get(correctField)
      }
    }

    var expectedEarningsSite2 = 0
    if (otherIdeaResults) {
      for (var i in otherIdeaResults) {
        expectedEarningsSite2 = expectedEarningsSite2 + otherIdeaResults[i].get(correctField)
      }
    }

    return [expectedEarningsSite1, expectedEarningsSite2]

  }

  // Setups actual return by site
  setupActualEarningsBySiteData() {
    const { verifications, otherVerifications } = this.state;

    var actualEarningsSite1 = 0
    if (verifications) {
      for (var i in verifications) {
        actualEarningsSite1 = actualEarningsSite1 + verifications[i].get("money")
      }
    }

    var actualEarningsSite2 = 0
    if (otherVerifications) {
      for (var i in otherVerifications) {
        actualEarningsSite2 = actualEarningsSite2 + otherVerifications[i].get("money")
      }
    }

    return [actualEarningsSite1, actualEarningsSite2]
  }

  getActualEarningsByCategoryData(verified) {
    const { t } = this.props;

    const series = [
      {
        name: 'Actual Returns',
        data: verified.map((type) => {
          return { x: type.category, y: type.total }
        })
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          title: {
            text: t('IDEA_MANAGE_CATS'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        xaxis: {
          title: {
            text: t('BUSINESS_IMPACT'),
            style: {
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (val) {
              return val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return val.toLocaleString()
            }
          }
        }
      }
    };

    return demoData
  }


  getOfficialNums() {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;

    var officialNums = {}

    // Check if ideas and other ideas loaded


    if (selectedOption == 'All Sites') {

      officialNums.numCompleted = String(parseInt(numCompleted) + parseInt(numCompletedOther))
      officialNums.numInProgress = String(parseInt(numInProgress) + parseInt(numInProgressOther))
      officialNums.numPendingEval = String(parseInt(numPendingEval) + parseInt(numPendingEvalOther))
      officialNums.numSubmitted = String(parseInt(numSubmitted) + parseInt(numSubmittedOther))

    } else if (selectedOption == 'SFO') {
      officialNums.numCompleted = parseInt(numCompleted)
      officialNums.numInProgress = parseInt(numInProgress)
      officialNums.numPendingEval = parseInt(numPendingEval)
      officialNums.numSubmitted = parseInt(numSubmitted)
    } else if (selectedOption == 'Amgen') {
      officialNums.numCompleted = parseInt(numCompletedOther)
      officialNums.numInProgress = parseInt(numInProgressOther)
      officialNums.numPendingEval = parseInt(numPendingEvalOther)
      officialNums.numSubmitted = parseInt(numSubmittedOther)
    }

    return officialNums

  }

  getDemoData() {
    const { selectedOption, ideas, otherIdeaResults } = this.state;

    const demoData = {
      series: [
        {
          name: 'Ideas per Site',
          data: [

          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },

        xaxis: {
          categories: ['SFO', 'Amgen'],
        },
        yaxis: {
          title: {
            text: 'Number of Ideas',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    };

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'SFO',
          y: ideas.length,
        },
        {
          x: 'Amgen',
          y: otherIdeaResults.length,
        }
      ]

    } else if (selectedOption == 'SFO') {

      demoData.series[0].data = [
        {
          x: 'SFO',
          y: ideas.length,
        },
      ]

    } else if (selectedOption == 'Amgen') {

      demoData.series[0].data = [
        {
          x: 'Amgen',
          y: otherIdeaResults.length,
        },
      ]

    }

    return demoData
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }


  getEarningsBySiteData(type) {
    const { selectedOption } = this.state;
    const expectedEarningsPerSite = this.setupEarningsBySiteData(type)
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]

    const demoData = {
      series: [
        {
          name: 'Earnings per Site',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },

        yaxis: {
          title: {
            text: 'Site',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        xaxis: {
          title: {
            text: 'Expected Earnings'
          },
          labels: {
            formatter: function (val) {
              return '$' + val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return '$' + val.toLocaleString()
            }
          }
        }
      }
    };


    if (selectedOption == 'All Sites') {


      demoData.series[0].data = [
        {
          x: 'SFO',
          y: expectedEarningSite1,
        },
        {
          x: 'Amgen',
          y: expectedEarningsSite2,
        }
      ]

    } else if (selectedOption == 'SFO') {

      demoData.series[0].data = [
        {
          x: 'SFO',
          y: expectedEarningSite1,
        },
      ]

    } else if (selectedOption == 'Amgen') {

      demoData.series[0].data = [
        {
          x: 'Amgen',
          y: expectedEarningsSite2,
        }
      ]

    }

    return demoData

  }
  getIdeasByStatusData() {
    const { t } = this.props;
    const demoData = {
      series: [
        {
          name: 'Ideas by Status',
          data: [

          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },

        xaxis: {
          categories: ['Submitted', 'Evaluated', 'In Progress', 'Closed', 'Verified', 'R&R'],
        },
        yaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    };

    demoData.series[0].data = [
      {
        x: 'Submitted',
        y: toInteger(this.state.ideas.submitted),
      },
      {
        x: 'Evaluated',
        y: toInteger(this.state.ideas.evaluated),
      },
      {
        x: 'In Progress',
        y: toInteger(this.state.ideas.inProgress),
      },
      {
        x: 'Closed',
        y: toInteger(this.state.ideas.closed),
      },
      {
        x: 'Verified',
        y: toInteger(this.state.ideas.verified),
      },
      {
        x: 'R&R',
        y: toInteger(this.state.ideas.rewardRecognition),
      },
    ]

    return demoData
  }

  downloadCsv = async (type) => {
    if (!type) type = 'report';
    this.setState({ loadingButton: true, loadingType: type });

    try {
      const csvData = await reportsService.downloadReportsLocalIdeas(type);
      if (!csvData || csvData.length === 0) {
        this.setState({ loading: false });
        return;
      }

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `${type}_${currentDate}.csv`;

      saveAs(blob, fileName);
    } catch (error) {
      console.error('Error al descargar los datos:', error);
    } finally {
      this.setState({ loadingButton: false, loadingType: type });
    }
  };

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  render() {
    const { t } = this.props;
    const { ideas,
      indContData,
      indDeptData,
      totalAmountEstimated,
      totalVerified,
      ideasPerCategory,
      ideasPerType,
      earningsByCategoryData,
      actualEarningsByCategoryData,
      loadingButton,
      loadingType } = this.state;
    const currentUser = authUser();
    const isMurmurattoUser = currentUser.roles.includes('murmuratto')
    const isSuperUser = currentUser.roles.includes('super_user') || currentUser.roles.includes('murmuratto')
    const isDeptLeader = currentUser.roles.includes('department_leader')
    const ideasByType = this.state.ideasByTypeForPie;
    const ideasByCategory = this.state.ideasByCategoryForPie;
    const ideasByStatusData = this.getIdeasByStatusData()

    var smallStatsLocal = [
      {
        label: t("OPPORTUNITIES_SUBMITTED"),
        value: ideas.submitted,
        percentage: "12.4%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.primary.toRGBA(0.1),
            borderColor: colors.primary.toRGBA(),
            data: [9, 3, 3, 9, 9]
          }
        ]
      },
      {
        label: t('CLOSE_OPPORTUNITIES'),
        value: ideas.closed,
        valueDuplicates: ideas.closedDuplicates,
        valueImplemented: ideas.closedImplemented,
        valueNotImplemented: ideas.closedNotImplemented,
        valueDoNotPursue: ideas.closedDoNotPursue,
        valueOther: 0,
        percentage: "7.21%",
        increase: false,
        chartLabels: [null, null, null, null, null],
        decrease: true,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.success.toRGBA(0.1),
            borderColor: colors.success.toRGBA(),
            data: [3.9, 4, 4, 9, 4]
          }
        ]
      },
      {
        label: t('PENDING_FOR_DECISION'),
        value: ideas.pendingEvaluation,
        percentage: "3.71%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.warning.toRGBA(0.1),
            borderColor: colors.warning.toRGBA(),
            data: [6, 6, 9, 3, 3]
          }
        ]
      },
      {
        label: t('OPPORTUNITIES_UNDER_IMPLEMENTATION'),
        value: ideas.inProgress,
        percentage: "3.71%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.warning.toRGBA(0.1),
            borderColor: colors.warning.toRGBA(),
            data: [6, 6, 9, 3, 3]
          }
        ]
      },
    ];

    // Top Individual Contributors
    const tableColumnsIndCont = [
      {
        Header: "Employee Name",
        accessor: "name",
        className: "text-center"
      },
      {
        Header: "Department",
        accessor: "department",
        className: "text-center"
      },
      {
        Header: "Qty",
        accessor: "count",
        className: "text-center",
        style: { 'whiteSpace': 'unset' },
        maxWidth: 60,
      },
    ];

    const tableColumnsDeptCont = [
      {
        Header: "Department",
        accessor: "department",
        className: "text-center"
      },
      {
        Header: "Dept. Leader",
        accessor: "leader",
        className: "text-center"
      },
      {
        Header: "Qty",
        accessor: "count",
        className: "text-center",
        style: { 'whiteSpace': 'unset' },
        maxWidth: 60,
      },
    ];

    const formatAmount = (amount) => {
      if (amount >= 1000) {
        const formattedAmount = Math.floor(amount / 1000);
        return formattedAmount + 'k';
      }
      return amount.toString();
    }

    const defaultSorted = [
      {
        id: 'qty', 
        desc: true,
      },
    ];

    const downloadReport = (isSuperUser || isDeptLeader)

    const progress = this.state.ideas ? this.state.ideas.progress : null
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col xs="12" sm="4" className="col d-flex align-items-center">
            <PageTitle title={t('REPORTS_DASHBOARD')} subtitle={t('REPORTS')} className="text-sm-left mb-3" />
          </Col>
          <Col xs="12" sm="4" className="col d-flex align-items-center">
            <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
              {downloadReport ?
                <>
                  {loadingButton && loadingType === 'report' ? (
                    <Button pill theme="white" disabled style={{ display: 'flex' }}>
                      <ReactLoading type="spokes" color="#633FDA" width={18} height={18} />
                      <div style={{ marginLeft: 10 }}>Downloading Data...</div>
                    </Button>
                  ) : (
                    <Button pill theme="white" onClick={() => this.downloadCsv('report')}>
                      Download Data
                    </Button>
                  )}
                </>
                : null}

              {isMurmurattoUser ?
                <>
                  {loadingButton && loadingType === 'aging' ? (
                    <Button pill theme="white" disabled style={{ display: 'flex' }}>
                      <ReactLoading type="spokes" color="#633FDA" width={18} height={18} />
                      <div style={{ marginLeft: 10 }}>Downloading Data...</div>
                    </Button>
                  ) : (
                    <Button pill theme="white" onClick={() => this.downloadCsv('aging')}>
                      Download Aging Report
                    </Button>
                  )}
                </>
                : null}

            </ButtonGroup>
          </Col>
        </Row>
        <h3 style={{ textAlign: "center", padding: "12px 0" }}>{t("OPPORTUNITTIES_DETAILS")}</h3>

        <Row className="flex">
          {smallStatsLocal.map((stats, idx) => (
            <Col key={idx} md="6" lg="3" className="mb-4">
              <Card className="cardCenter">
                <CardBody style={{ position: 'relative' }}>
                  <p>{stats.label}</p>
                  {isNaN(stats.value) ? <ReactLoading type={'spokes'} color={'#633FDA'} width={40} height={40} /> : <p style={{ fontSize: 50, fontWeight: 700, color: '#3f8cf4' }}>{stats.value === 0 ? '0' : stats.value}</p>}
                  {stats.label === t('CLOSE_OPPORTUNITIES') && <>
                      <div style={{ position: 'absolute', bottom: '10px' }}>
                           <a id={"ModalTooltipResponseInfo"} style={{ color: 'inherit' }} onClick={() => {
                          var myCopy = this.state.responseInfo
                          myCopy = !myCopy
                          this.setState({ responseInfo: myCopy })
                        }}>
                          <Button pill size="sm" theme="primary" style={{ padding: '3px 25px' }}>
                            Details
                          </Button>
                        </a>

                        <Tooltip
                          open={this.state.responseInfo}
                          target={"#ModalTooltipResponseInfo"}
                          id={"ModalTooltipResponseInfo1"}
                          toggle={() => { }}
                        >
                         <div>Implemented: {stats.valueImplemented === 0 ? '0' : stats.valueImplemented}</div>  
                         <div>Not Implemented: {stats.valueNotImplemented === 0 ? '0' : stats.valueNotImplemented}</div>
                         <div>Duplicate: {stats.valueDuplicates === 0 ? '0' : stats.valueDuplicates}</div>
                         <div>Do Not Pursue: {stats.valueDoNotPursue === 0 ? '0' : stats.valueDoNotPursue}</div>  
                        </Tooltip>
                      </div>
                  </>}

                  {stats.label === t('OPPORTUNITIES_UNDER_IMPLEMENTATION') && <>
                      <div style={{ position: 'absolute', bottom: '10px' }}>
                           <a id={"ModalTooltipResponseInfoProgress"} style={{ color: 'inherit' }} onClick={() => {
                          var myCopy = this.state.responseInfoProgress
                          myCopy = !myCopy
                          this.setState({ responseInfoProgress: myCopy })
                        }}>
                          <Button pill size="sm" theme="primary" style={{ padding: '3px 25px' }}>
                            Details
                          </Button>
                        </a>

                        <Tooltip
                          open={this.state.responseInfoProgress}
                          target={"#ModalTooltipResponseInfoProgress"}
                          id={"ModalTooltipResponseInfoProgress1"}
                          toggle={() => { }}
                        >
                        {progress &&
                         <><div>25%: {progress['25']}</div>
                         <div>50%: {progress['50']}</div>
                         <div>75%: {progress['75']}</div>
                         <div>100%: {progress['100']}</div></>
                         }
                        </Tooltip>
                      </div>
                  </>}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          <Col lg="12" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500, paddingLeft: 30, paddingRight: 30 }}>{t('EMPLOYEE_OPPORTUNITY_WORKFLOW_STAGE')}</p>
                <BarChart data={ideasByStatusData}></BarChart>
              </CardBody>
            </Card>
          </Col>

          {/* Earnings */}
          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                  <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('OPPORTUNITIES_BY_CATEGORY_PERCENTAGE')}
                  </p>
                  <p style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500 }}>
                    {t("PERCENTAGE")}
                  </p>
                </div>
                {ideasByType && <PieChart data={ideasByType} id={'pie1'} key={"pieChart-" + Date.now()}></PieChart>}
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                  <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('OPPORTUNITIES_BY_CATEGORY_QUANTITY')}
                  </p>
                  <p style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500 }}>
                    {t("QUANTITY")}
                  </p>
                </div>
                {ideasPerCategory && ideasPerCategory.series && ideasPerCategory.series.length > 0 ?
                  <>
                    <BarChart data={ideasPerCategory}></BarChart>
                  </>
                  :
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                    <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
                  </div>
                }
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                  <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('OPPORTUNITIES_BY_TYPE_PERCENTAGE')}
                  </p>
                  <p style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500 }}>
                    {t("PERCENTAGE")}
                  </p>
                </div>
                {ideasByCategory && <PieChart data={ideasByCategory} id={"pie2" + ideasByCategory.length} key={"pieChart-" + Date.now()}></PieChart>}
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                  <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('OPPORTUNITIES_BY_TYPE_QUANTITY')}
                  </p>
                  <p style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500 }}>
                    {t("QUANTITY")}
                  </p>
                </div>
                {ideasPerType && ideasPerType.series && ideasPerType.series.length > 0 ?
                  <>
                    <BarChart data={ideasPerType}></BarChart>
                  </>
                  :
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                    <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
                  </div>
                }
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30, marginTop: 32 }}>
                  <p style={{ textAlign: 'center', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('BUSINESS_IMPACT')}
                  </p>
                  <p style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500 }}>
                    {t("ESTIMADO")}
                  </p>
                </div>
                <h2 style={{ textAlign: 'center', marginBottom: 30 }}>{formatAmount(totalAmountEstimated)}</h2>
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, paddingRight: 30, marginTop: 32 }}>
                  <p style={{ textAlign: 'center', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('BUSINESS_IMPACT')}
                  </p>
                  <p style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500 }}>
                    {t("ACTUAL")}
                  </p>
                </div>
                <h2 style={{ textAlign: 'center', marginBottom: 30 }}>{formatAmount(totalVerified)}</h2>
              </CardBody>

            </Card>
          </Col>
          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                  <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('BUSINESS_IMPACT')}
                  </p>
                  <p style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500 }}>
                    {t("ESTIMADO")}
                  </p>
                </div>
                {/* {this.state.showChart && <ProgressPerCategoryChart data={this.state.returnsByCategoryData}></ProgressPerCategoryChart>} */}
                {earningsByCategoryData && earningsByCategoryData.series && earningsByCategoryData.series.length > 0 ?
                  <>
                    <BarChart data={earningsByCategoryData}></BarChart>
                  </>
                  :
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                    <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
                  </div>
                }
              </CardBody>
              <CardFooter>

              </CardFooter>
            </Card>

          </Col>

          {/* Actual Earnings */}
          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                  <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('BUSINESS_IMPACT')}
                  </p>
                  <p style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500 }}>
                    {t("ACTUAL")}
                  </p>
                </div>
                {actualEarningsByCategoryData && actualEarningsByCategoryData.series && actualEarningsByCategoryData.series.length > 0 ?
                  <>
                    <BarChart data={actualEarningsByCategoryData}></BarChart>
                  </>
                  :
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                    <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
                  </div>
                }

              </CardBody>
              <CardFooter>

              </CardFooter>
            </Card>
          </Col>

          {isMurmurattoUser &&
            <Col lg="12" sm="12" className="mb-4">
              <Card className="cardMinHeight">
                <CardBody>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                    <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                      {t('Opportunity Flow')}
                    </p>
                  </div>
                  <SankeyChart opportunities={ideas.flow}></SankeyChart>
                </CardBody>
              </Card>
            </Col>
          }

          <Col lg="6" sm="12" className="mb-4">
            <Card className="cardMinHeight">
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                  <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('EMPLOYEES_WITH_MOST_OPPORTUNITIES')}
                  </p>
                </div>
                <div className={'transparent-responsive-card transparent'}>
                  <ResponsiveTable
                    columns={tableColumnsIndCont}
                    data={indContData}
                    pageSize={5}
                    showPageSizeOptions={false}
                    resizable={false}
                    defaultSorted={defaultSorted}
                  />
                </div>
              </CardBody>

            </Card>
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card className="cardMinHeight">
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                  <p style={{ textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500 }}>
                    {t('DEPARTMENTS_RECEIVING_MOST_OPPORTUNITIES')}
                  </p>
                </div>
                <div className={'transparent-responsive-card transparent'}>

                  <ResponsiveTable
                    columns={tableColumnsDeptCont}
                    data={indDeptData}
                    pageSize={5}
                    showPageSizeOptions={false}
                    resizable={false}
                    defaultSorted={defaultSorted}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>

      </Container>
    )
  }
}


EnterpriseR2.defaultProps = {
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(EnterpriseR2);
