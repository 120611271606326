import React from "react";
import { withTranslation } from 'react-i18next';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import { fetchRecognizeInbox } from "../../services/recognizeService";
import ResponsiveTable from "../responsive-table/ResponsiveTable";

class RecognitionTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ideas: [],
      evalCriteria: [],
      filteredIdeas: [],
      title: '',
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      search: ''
    };

    this.fetchIdeas = this.fetchIdeas.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentDidMount() {
    console.log('Component did mount');
    this.fetchIdeas();
  }

  componentWillReceiveProps(newProps) {
    console.log('Component will receive props');
    this.filterIdeas(newProps.category, newProps.status);
  }

  async fetchIdeas() {
    console.log('Fetching ideas');
    try {
      // Call your service to fetch the recognition ideas
      const recognitionIdeas = await fetchRecognizeInbox();
      // Assume the response data structure is similar to Parse object, map it if necessary
      const ideas = recognitionIdeas.map(recognition => ({
        ...recognition,
        objectId: recognition.id, // Assuming it has an id field
        num: recognition.num,
        title: recognition.title,
        category: recognition.category,
        proponentName: recognition.proponent,
        status: recognition.status,
        verified: false
      }));

      this.setState({
        ideas,
        filteredIdeas: ideas
      });
    } catch (error) {
      alert('Hubo un error en la búsqueda. Favor de intentar nuevamente más tarde.');
    }
  }

  filterIdeas(categoryName, status) {
    const newCategory = categoryName;
    const newStatus = status;

    const { ideas } = this.state;

    let filteredData = ideas;
    if (newCategory && newCategory !== 'Todas') {
      filteredData = filteredData.filter(item => item.category === newCategory);
    }
    if (newStatus && newStatus !== 'Todos') {
      filteredData = filteredData.filter(item => item.status === newStatus);
    }

    this.setState({ filteredIdeas: filteredData });
  }

  handlePageSizeChange(e) {
    this.setState({ pageSize: e.target.value });
  }

  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm }, () => {
      console.log('searchTerm', this.state.ideas);
      const filteredData = this.state.ideas.filter(idea => {

        return idea.title.toLowerCase().includes(searchTerm) ||
        idea.num == searchTerm ||
        idea.category.toLowerCase().includes(searchTerm) ||
        idea.status.toLowerCase().includes(searchTerm)
      }
      );
      this.setState({ filteredIdeas: filteredData });
    });
  }

  render() {
    const { filteredIdeas, pageSize, pageSizeOptions, search } = this.state;
    const { t } = this.props;

    const columns = [
      {
        Header: 'Actions',
        accessor: "open",
        className: "text-center",
        width: 150,
        Cell: row => (
          <>
            <Badge style={{ cursor: 'pointer', marginRight: '10px' }} outline pill  onClick={() => this.props.onViewIdeaPress(row.original)}>
              View
            </Badge>
            <Badge pill outline size="sm" style={{cursor:'pointer'}} onClick={() => this.props.onEditActionPress(row.original)}>
              Recognize
            </Badge>
          </>
        )
      },

      {
        Header: t("IDEA_MANAGE_NUM"),
        width: 120,
        accessor: "num",
        className: "text-center"
      },
      {
        Header: t("IDEA_MANAGE_TITLE"),
        accessor: "title",
        className: "text-center"
      },
      {
        Header: t("IDEA_MANAGE_CATEGORY"),
        accessor: "category",
        width: 200,
        style: {color: '#17c671'},
        className: "text-center"
      },
      {
        Header: t("IDEA_MANAGE_PROP"),
        width: 200,
        accessor: "proponentName",
        className: "text-center"
      },
      {
        Header: t("IDEA_MANAGE_STATUS"),
        accessor: "status",
        width: 150,
        className: "text-center",
        Cell: row => (
          <div className="d-table mx-auto">
            <Badge pill theme={getBadgeType(row.value)}>
              {row.value}
            </Badge>
          </div>
        )
      }
    ];

    return (
      <Card className={'transparent-responsive-card'}>
        <CardHeader className="p-0 responsive-table-filter">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col className="file-manager__filters__rows d-flex" md="6">
                <span>{t('SHOW')}</span>
                <FormSelect
                  size="sm"
                  value={pageSize}
                  onChange={this.handlePageSizeChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size + ' ' + t('ROWS')}
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col className="file-manager__filters__search d-flex" md="6">
                <InputGroup seamless size="sm" className="ml-auto">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput value={search} onChange={this.handleFilterSearch} />
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <Container fluid className="px-0">
            <ResponsiveTable
              columns={columns}
              data={filteredIdeas}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              showPageSizeOptions={false}
              resizable={false}
            />
          </Container>
        </CardBody>
      </Card>
    );
  }
}

function getBadgeType(itemStatus) {
  const statusMap = {
    "Complete": "success",
    "Saved": "warning",
    "Canceled": "danger"
  };
  return statusMap[itemStatus];
}

export default withTranslation()(RecognitionTable);
