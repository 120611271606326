import Parse from 'parse';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "shards-react";

import CommitteeItemBeta from '../components/administration/CommitteeItemBeta';

import colors from "../utils/colors";

import { ReactComponent as AddCommitteeIcon } from "../images/add_a_committee.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";



import { useTranslation } from "react-i18next";


function AdministrationCommitteesBeta(smallStats) {
  const { t } = useTranslation();
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(1)
  const [category, setCategory] = useState('')
  const [canGoNext, setCanGoNext] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [finishedSaving, setFinishedSaving] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(false)
  const [committees, setCommittees] = useState([])
  const [newCategory, setNewCategory] = useState('')

  const setInitialTitle = async (user) => {
    setTitle('Committees')
  }

  useEffect(() => {
    // Update the document title using the browser API
    setInitialTitle()
    getCategories()
  }, []);

  const getCategories = async() => {
    const Category = Parse.Object.extend("EvaluationCommittee");
    const query = new Parse.Query(Category);
    const results = await query.find();
    
    setCommittees(results)
  }

  const changeIdeaStage = () => {
    const newStage = ideaStage + 1
    
    if (newStage == 0) {
      setTitle('Welcome back, ' + username+ '!')
      setIdeaStage(newStage)
    } else if (newStage == 1) {
      setTitle(t('Opportunity >') + ' Select Idea Type')
      setIdeaStage(newStage)
    } else if (newStage == 2) {
      setTitle(t('Opportunity >') + ' Innovation > Idea Details')
      setIdeaStage(newStage)
    } else if (newStage == 3) {
      setTitle(t('Opportunity >') + ' Innovation > Idea Details > Done')
      setCanSubmit(true)
    } else {
      setTitle('Welcome back, ' + username+ '!')
      setIdeaStage(0)
    }
  }

  const updateCategories = () => {
    alert('Your committee was updated succesfully!')
    setNewCategory('')
    getCategories()
  }

  const createCategory = () => {
    const EvalCommittee = Parse.Object.extend("EvaluationCommittee");
    const ideaCategory = new EvalCommittee();

    ideaCategory.set("name",'');
    ideaCategory.set("value", '');
    ideaCategory.set("evaluationType", '');
    ideaCategory.set("members", '');
    ideaCategory.set("type", '');
    ideaCategory.set("canDelete", true);
    ideaCategory.set("category", '');
    ideaCategory.set("department", '');
    ideaCategory.set("customUpdatedAt", new Date());
    setNewCategory(ideaCategory)
  }

  return (
  <Container fluid className="main-content-container px-4 pb-4" style={{backgroundColor: 'white'}}>
    <Row>
      {/* Page Header :: Title */}
      <Col md="10" lg="10" className="mt-4 mx-auto">
        {/* <PageTitle title={t('Welcome back, Angel')} subtitle=" " className="text-sm-left" /> */}
        <Row>
          <Col md="8" lg="8">
            <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
          </Col>
          
          <Col xs="12" md="2" lg="2" className="col d-flex align-items-center ml-auto">
            {/* <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
              <Button theme="white" tag={NavLink} to="/submit-idea">
                {t('SUBMIT')}
              </Button>
              <Button theme="white" tag={NavLink} to="/search-idea">
                {t('SEARCH')}
              </Button>
            </ButtonGroup> */}
           
          </Col>
        </Row>
        <Row>
          <Col>
            <DivisorBarIcon></DivisorBarIcon>
          </Col>
        </Row>
        
      </Col>
      {/* Page Header :: Actions */}
      
    </Row>
    <Row className="mt-4">
      <Col lg="10" className="m-auto">
       
        <AddCommitteeIcon className="functionalButton mr-4 d-block mb-4" style={{minWidth: 180, maxWidth:180, overflow: 'visible'}} onClick={() => createCategory()}></AddCommitteeIcon>
       
        {/* {ideaStage == 0 && canGoNext && <SubmitIcon className="ml-auto d-block" style={{minWidth: 140, maxWidth:140}} onClick={() => changeIdeaStage()}></SubmitIcon>}
        {ideaStage == 0 && !canGoNext && <SubmitIconNosel className="ml-auto d-block" style={{minWidth: 140, maxWidth:140}} ></SubmitIconNosel>}
        

        {ideaStage > 0 && ideaStage <= 2 && canContinue &&  <NextIconValid className="ml-auto d-block mb-4" style={{minWidth: 140, maxWidth:140}} onClick={() => changeIdeaStage()}></NextIconValid>}
        {ideaStage > 0 && ideaStage <= 2 && !canContinue && <NextIcon className="ml-auto d-block mb-4" style={{minWidth: 140, maxWidth:140}} ></NextIcon>}
        
        {ideaStage == 3  && <HomeIcon className="ml-auto d-block mb-4" style={{minWidth: 90, maxWidth:90}} onClick={() => changeIdeaStage()}></HomeIcon>} */}
      </Col>
    </Row>
    
    {/* Divider */}
    {/* <Col xs="12" md="12" style={{height: 1, width: '100%', backgroundColor: 'black', opacity: 0.2}} className="col d-flex align-items-center"></Col> */}

    {/* Categories */}

    {newCategory && 
      <Row className="mt-2">
       <Col md="12" lg="12">
          <CommitteeItemBeta key={Math.random()} id={Math.random()} category={newCategory} isNew={true} updateCategories={updateCategories}></CommitteeItemBeta>
       </Col>
      </Row>
    }

    { committees && committees.map((committee, i) => {
      return (
        <Row className="mt-2 ">
          <Col md="12" lg="12">
            <CommitteeItemBeta key={i+Math.random()} id={i+Math.random()} category={committee} updateCategories={updateCategories}></CommitteeItemBeta>
          </Col>
        </Row>
      )
    })}

    

    
    
    
  </Container>
  )}

AdministrationCommitteesBeta.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

AdministrationCommitteesBeta.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default AdministrationCommitteesBeta;
